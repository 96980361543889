

// https://stackoverflow.com/questions/58191398/how-to-convert-decimal-number-to-cents-in-angular-or-javascript

export default function dollarsToCents(dollars: string): number {
    let cleanValue = (dollars + '').replace(/[^\d.-]/g, '');
    if (cleanValue && cleanValue.includes('.')) {
        cleanValue = cleanValue.substring(0, cleanValue.indexOf('.') + 3);
    }
    let cents = cleanValue ? Math.round(parseFloat(cleanValue) * 100) : 0;
    return cents
}