import AntdDrawer, { DrawerProps } from "antd/lib/drawer";
import "antd/lib/drawer/style/css";
import { useWindowWidth } from "@react-hook/window-size";

export default function Drawer(props: DrawerProps | any) {
  const screenWidth = useWindowWidth();
  return (
    <AntdDrawer
      placement="right"
      width={screenWidth < 600 ? screenWidth : 600}
      height={200}
      destroyOnClose
      getContainer={false}
      style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      {...props}
    >
      {/* {props?.children} */}
    </AntdDrawer>
  );
}
