import {
  EntryUploadInput,
  useUploadManyEntriesMutation,
} from "generated/graphql";
import UploadButton from "components/common/UploadButton";
import PageContainer from "components/common/PageContainer";
import Papa from "papaparse";
import moment from "moment";
import { useState } from "react";
import dollarsToCents from "lib/helpers/dollarsToCents";
import message from "components/common/message";

/**
 *
 */
const showBizColumnMapper = {
  firstName: 54,
  lastName: 57,
  state: 85,
  rowId: 9,
  totalPay: 152,
  weekEnding: 157,
  showName: 159,
  timeAndAHalfMoney: 172,
  dailyDoubleTimeMoney: 173,
  travelTimeMoney: 174,
  otherDoubleTimeMoney: 176,
  characterName: 196,
  workDates: 252,
  daysWorked: 253,
  episode: 154,
};

const hashObject = (string: string) => {
  let hash = 0;
  if (string.length === 0) {
    return hash;
  }
  for (var i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

const getDates = (
  entry: any,
  workDates: string[]
): { weekEnding: string; weekStarting: string; dueDate: Date } => {
  const year = moment(entry[showBizColumnMapper?.weekEnding], "MMMM DD, YYYY")
    ?.format("YYYY")
    ?.trim();

  const weekEnding =
    workDates?.[0] &&
    moment(`${workDates?.[0]}/${year}`, "M/D/YYYY")
      ?.endOf("day")
      ?.endOf("week") // set it to saturday
      ?.subtract(1, "days")
      ?.format("MM/DD/YYYY")
      ?.trim(); // set it to friday

  const weekStarting =
    workDates?.[0] &&
    moment(`${workDates?.[0]}/${year}`, "M/D/YYYY")
      ?.startOf("day")
      ?.startOf("week") // set to sunday
      ?.subtract(1, "days") // set it to saturday
      ?.format("MM/DD/YYYY") //
      ?.trim();

  return {
    weekStarting,
    weekEnding: moment(weekEnding)?.format("MM/DD/YYYY")?.trim(),
    dueDate: moment(weekEnding, "MM/DD/YYYY")
      ?.endOf("day")
      ?.utc()
      ?.add(12, "days")
      ?.toDate(), // add 12 days to the week end (also offset to be NYC time -5)
  };
};

export default function AdminUpload() {
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadManyEntriesMutation] = useUploadManyEntriesMutation();

  const onUploadCSV = async (results: any) => {
    console.log(results?.data?.[0]);

    const entries = results?.data?.map((entry: any): EntryUploadInput => {
      const year = moment(
        entry[showBizColumnMapper?.weekEnding],
        "MMMM DD, YYYY"
      )
        ?.format("YYYY")
        ?.trim();

      const workDates = entry[showBizColumnMapper?.workDates]
        ?.split("\x1D")
        .filter((i: any) => i)
        ?.map((date: string) => {
          return `${date}/${year}`;
        });

      const { weekStarting, weekEnding, dueDate } = getDates(entry, workDates);

      const obj = {
        firstName: entry[showBizColumnMapper?.firstName]?.trim(),
        lastName: entry[showBizColumnMapper?.lastName]?.trim(),
        rowId: entry[showBizColumnMapper?.rowId]?.trim(),
        totalPay: dollarsToCents(entry[showBizColumnMapper?.totalPay]?.trim()),
        weekEnding,
        weekStarting,
        dueDate,
        showName: entry[showBizColumnMapper?.showName]?.trim(),
        characterName: entry[showBizColumnMapper?.characterName]?.trim(),
        workDates,
        daysWorked: entry[showBizColumnMapper?.daysWorked]?.trim(),
        episode: entry[showBizColumnMapper?.episode]?.trim(),
      };
      return {
        ...obj,
        hash: hashObject(JSON.stringify(obj))?.toString(),
        stringify: JSON.stringify(obj),
      };
    });

    await uploadManyEntriesMutation({
      variables: {
        data: entries,
      },
    });
    message.success("Upload successfully completed");
    setLoading(false);
  };

  return (
    <PageContainer>
      <div style={{ marginBottom: 32 }}>
        <UploadButton
          id="showbiz-upload"
          label="Showbiz Upload"
          loading={loading}
          onChange={(e: any) => {
            try {
              if (e?.target?.files?.[0]) {
                setLoading(true);
                Papa.parse(e?.target?.files?.[0], {
                  // header: true,
                  skipEmptyLines: true,
                  complete: onUploadCSV,
                });
              }
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </div>
    </PageContainer>
  );
}
