import Table from "components/common/Table";
import {
  Attachment,
  Entry,
  EntryPaymentStatusEnum,
  EntrySortByEnum,
  SortOrderEnum,
  useUpdateOneEntryPaymentStatusMutation,
} from "generated/graphql";
import entryTypeToLabel from "lib/helpers/entryTypeToLabel";
import moment from "moment";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import Tag from "components/common/Tag";
import paymentStatusToLabel from "lib/helpers/paymentStatusToLabel";
import { useState } from "react";
import { CloseCircleFilled, EditFilled } from "@ant-design/icons";
import PaymentStatusSelectInput from "components/inputs/PaymentStatusSelectInput";
import Tooltip from "components/common/Tooltip";
import theme from "lib/theme";
import message from "components/common/message";
import { useWindowWidth } from "@react-hook/window-size";
import styled from "styled-components";
import { isNil } from "lodash";
import { TablePaginationConfig } from "antd";
import forceDownloadFile from "lib/helpers/forceDownloadFile";

const TooltipText = styled.p`
  font-size: 14px;
  margin: 0px;
`;

const TooltipCaption = styled.span`
  font-size: 14px;
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral4};
`;

const DownloadAttachmentBtn = styled.button`
  border: 0px;
  background-color: "transparent";
  margin: 1px;
  cursor: pointer;
`;

const getSortOrder = (
  sort: { field: EntrySortByEnum; order: SortOrderEnum }[],
  column: EntrySortByEnum
): "descend" | "ascend" | undefined => {
  const sortField = sort?.find((item: any) => item.field === column);
  if (!sortField) return undefined;
  return sortField?.order === SortOrderEnum.Desc ? "descend" : "ascend";
};

export default function EntriesTable({
  loading,
  dataSource,
  pagination,
  onChange,
  sort,
}: {
  dataSource?: any[];
  loading?: boolean;
  pagination?: TablePaginationConfig;
  onChange: any;
  sort: any;
}) {
  const [updateOneEntryPaymentStatusMutation, { loading: updatingStatus }] =
    useUpdateOneEntryPaymentStatusMutation();
  const [editable, setEditable] = useState<false | string>(false); // is either false, or holds the ID of the record you're editing

  const screenWidth = useWindowWidth();

  const columns = [
    {
      title: "Picture",
      key: EntrySortByEnum.Picture,
      sortOrder: getSortOrder(sort, EntrySortByEnum.Picture),
      sorter: true,
      render: (record: Entry) => {
        return (
          <>
            <Text>
              <strong>{record?.picture?.title}</strong>
            </Text>
            {record?.episode && (
              <Caption>{record.episode && `e${record.episode}`}</Caption>
            )}
          </>
        );
      },
    },
    {
      title: "Week",
      key: EntrySortByEnum.WeekStarting,
      sortOrder: getSortOrder(sort, EntrySortByEnum.WeekStarting),
      sorter: true,
      render: (record: Entry) => {
        return (
          <Tooltip
            title={
              <>
                <p style={{ color: "#fff", margin: 0, marginBottom: 4 }}>
                  Work dates: {record.daysWorked}
                </p>
                {record?.workDates?.map((day) => (
                  <p key={day} style={{ color: "#fff", margin: 0 }}>
                    {moment(
                      `${day}/${moment(record.weekEnding)?.format("YYYY")}`,
                      "M/D/YYYY"
                    )?.format(`ddd M/D/YY`)}
                  </p>
                ))}
              </>
            }
          >
            <Text>
              {moment(record?.weekStarting)?.format("ddd MM/DD/YYYY")} -
            </Text>
            <Text>{moment(record?.weekEnding)?.format("ddd MM/DD/YYYY")}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Days late",
      key: EntrySortByEnum.DaysLate,
      sortOrder: getSortOrder(sort, EntrySortByEnum.DaysLate),
      sorter: true,
      render: (record: Entry) => {
        if (!isNil(record?.daysLate) && record?.daysLate <= 0) return null;
        return (
          record?.daysLate && (
            <Tooltip
              title={`Due on ${moment(record.dueDate)?.format(
                "ddd MMM DD, YYYY"
              )}`}
            >
              <Text style={{ textAlign: "center" }}>{record?.daysLate}</Text>
            </Tooltip>
          )
        );
      },
    },
    {
      title: "Owed",
      key: EntrySortByEnum.Owed,
      sortOrder: getSortOrder(sort, EntrySortByEnum.Owed),
      sorter: true,
      render: (record: Entry) => {
        return <Text>{record?.owed}</Text>;
      },
    },
    {
      title: "Paid",
      key: EntrySortByEnum.Paid,
      sortOrder: getSortOrder(sort, EntrySortByEnum.Paid),
      sorter: true,
      render: (record: Entry) => {
        return <Text>{record?.paid}</Text>;
      },
    },
    // {
    //   title: "Info",
    // },
    {
      title: "Attachments",
      width: 150,
      render: (record: Entry) => {
        const stuff: Attachment[] = [
          ...(record?.attachments as any),
          ...(record?.incomes as any),
        ];
        return (
          <ul>
            {stuff?.map((attachment) => {
              if (!attachment) return null;
              return (
                <li key={attachment?.id}>
                  {typeof attachment?.url === "string" ? (
                    <DownloadAttachmentBtn
                      onClick={() => {
                        forceDownloadFile(
                          attachment?.url,
                          attachment?.filename || ""
                        );
                      }}
                    >
                      {entryTypeToLabel(attachment?.attachmentType)}
                    </DownloadAttachmentBtn>
                  ) : (
                    <>
                      <Tooltip
                        title={
                          <div style={{ width: 230 }}>
                            <TooltipText>
                              <TooltipCaption>Type: </TooltipCaption>
                              {entryTypeToLabel(attachment?.attachmentType)}
                            </TooltipText>
                            {attachment?.notes && (
                              <TooltipText>
                                {" "}
                                <TooltipCaption>Notes: </TooltipCaption>{" "}
                                {attachment?.notes}
                              </TooltipText>
                            )}
                            {attachment?.incomeSource && (
                              <TooltipText>
                                <TooltipCaption>Source: </TooltipCaption>{" "}
                                {attachment?.incomeSource}
                              </TooltipText>
                            )}
                            {attachment?.incomeType && (
                              <TooltipText>
                                <TooltipCaption>Income: </TooltipCaption>{" "}
                                {attachment?.incomeType}
                              </TooltipText>
                            )}
                            {attachment?.date && (
                              <TooltipText>
                                <TooltipCaption>Date: </TooltipCaption>{" "}
                                {moment(parseInt(attachment?.date))?.format(
                                  "MMM DD, YYYY"
                                )}
                              </TooltipText>
                            )}
                            {attachment?.incomeAmount && (
                              <TooltipText>
                                <TooltipCaption>Amount: </TooltipCaption>{" "}
                                {attachment?.incomeAmount}
                              </TooltipText>
                            )}
                          </div>
                        }
                      >
                        <Text>
                          {entryTypeToLabel(attachment?.attachmentType)}
                        </Text>
                      </Tooltip>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        );
      },
    },
    {
      title: "Updated",
      key: EntrySortByEnum.UpdatedAt,
      sortOrder: getSortOrder(sort, EntrySortByEnum.UpdatedAt),
      sorter: true,
      render: (record: Entry) => {
        return (
          <Text>
            {record?.updatedAt &&
              moment(parseInt(record?.updatedAt))?.format("MM/DD/YYYY")}
          </Text>
        );
      },
    },
    {
      title: "Status",
      width: 195,
      key: EntrySortByEnum.PaymentStatus,
      sortOrder: getSortOrder(sort, EntrySortByEnum.PaymentStatus),
      sorter: true,
      render: (record: Entry) => {
        if (editable === record.id) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <PaymentStatusSelectInput
                onChange={async (paymentStatus: EntryPaymentStatusEnum) => {
                  try {
                    if (updatingStatus) return;
                    if (!record.id) return;
                    await updateOneEntryPaymentStatusMutation({
                      variables: {
                        entryId: record.id,
                        paymentStatus,
                      },
                    });
                    message.success("Your entry was successfully updated");
                    setEditable(false);
                    // return confirm({
                    //   title: `Are you sure you want to update ${
                    //     deviceSession.sessionId
                    //   } from ${sessionStatusToLabel(
                    //     deviceSession?.status as DeviceSessionStatusEnum
                    //   )} to ${sessionStatusToLabel(
                    //     newValue as DeviceSessionStatusEnum
                    //   )}?`,
                    //   content:
                    //     "Please review these changes before confirming",
                    //   icon: <ExclamationCircleOutlined />,
                    //   type: "warning",
                    //   okText: `Yes, I'm sure`,
                    //   onOk: () =>
                    //     onUpdate(
                    //       deviceSession,
                    //       { status: newValue },
                    //       "status"
                    //     ),
                    // });
                  } catch (err) {}
                }}
                value={record?.paymentStatus}
                style={{ width: 140 }}
              />
              <Tooltip title="Cancel" key="cancel">
                <CloseCircleFilled
                  onClick={() => setEditable(false)}
                  style={{
                    marginLeft: 8,
                    fontSize: 12,
                    color: theme.colors.neutral6,
                  }}
                />
              </Tooltip>
            </div>
          );
        }
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag>{paymentStatusToLabel(record?.paymentStatus)}</Tag>
            <Tooltip title="Edit Status" key="edit">
              <EditFilled
                onClick={() => record?.id && setEditable(record?.id)}
                style={{ fontSize: 12, color: theme.colors.neutral6 }}
              />
            </Tooltip>
          </div>
        );
      },
    },
    // {
    //   title: "#",
    //   render: (record: Entry) => {
    //     return (
    //       <Tooltip title="The number of attachment entries">
    //         <Text>{record?.entries}</Text>
    //       </Tooltip>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        rowKey="id"
        scroll={screenWidth > 600 ? {} : { x: 1300 }}
        pagination={pagination}
        onChange={onChange}
      />
    </>
  );
}
