import styled from "styled-components";

export default styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.neutral1};
  margin: 0px;
  line-height: 135%;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    font-size: 14px;
  }
`;
