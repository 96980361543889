import storage from 'local-storage-fallback';
import iOS from './iOS';

export default function setLocalStorage(valueName: string, valueToSet: string) {
    try {
        if (!iOS()) {
            return window.localStorage.setItem(valueName, valueToSet);
        } else {
            return storage.setItem(valueName, valueToSet);
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}