import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import {
  GetManyPicturesDocument,
  GetManyPicturesQuery,
  Maybe,
  useCreateOnePictureMutation,
  useGetManyPicturesQuery,
} from "generated/graphql";
import DropdownStyleWrapper from "../DropdownStyleWrapper";
import { useState } from "react";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import message from "components/common/message";
import { Divider } from "antd";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const PicturesSelectInput = (props: {
  onChange: any;
  value?: Maybe<string>;
  style?: React.CSSProperties;
  placeholder?: string;
}) => {
  const { value, style, onChange } = props;
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [newTitle, setNewTitle] = useState<string | undefined>(undefined);
  const [createOnePictureMutation, { loading: creating }] =
    useCreateOnePictureMutation();
  const { data } = useGetManyPicturesQuery({
    variables: {
      searchText,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <DropdownStyleWrapper>
      <Select
        style={{
          maxWidth: "100%",
          width: "100%",
          ...style,
        }}
        {...props}
        value={value || undefined}
        showArrow={false}
        size="large"
        filterOption={false}
        optionFilterProp="children"
        placeholder={
          props?.placeholder || "Which show/movie is this related to?"
        }
        showSearch
        onSearch={(search) => setSearchText(search)}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
              <TextInput
                placeholder="Add a new picture"
                style={{ flex: "auto", height: 40, marginRight: 4 }}
                onChange={(e) => setNewTitle(e.target.value)}
              />
              <Button
                secondary
                disabled={creating}
                loading={creating}
                onClick={async () => {
                  try {
                    if (!newTitle || !newTitle?.[0]) return; // dont' submit if they havn't input any text
                    await createOnePictureMutation({
                      variables: {
                        params: {
                          title: newTitle,
                        },
                      },
                      update: async (cache, { data }) => {
                        const currentData: GetManyPicturesQuery | null =
                          await cache.readQuery({
                            query: GetManyPicturesDocument,
                            variables: {
                              searchText,
                            },
                          });
                        console.log(currentData?.getManyPictures);
                        await cache.writeQuery({
                          query: GetManyPicturesDocument,
                          variables: {
                            searchText,
                          },
                          data: {
                            ...currentData,
                            getManyPictures: {
                              ...currentData?.getManyPictures,
                              results: [
                                ...(currentData?.getManyPictures?.results ||
                                  []),
                                data?.createOnePicture,
                              ],
                              count:
                                (currentData?.getManyPictures?.count || 0) + 1,
                            },
                          },
                        });

                        onChange(data?.createOnePicture?.id);
                        setNewTitle("");
                      },
                    });
                  } catch (err: any) {
                    message.error(err?.message);
                    console.log(err);
                  }
                }}
              >
                Create
              </Button>
            </div>
          </div>
        )}
      >
        {data?.getManyPictures?.results?.map((picture) => {
          if (!picture?.id) return null;
          return (
            <Option key={picture?.id} value={picture?.id}>
              {picture?.title}
            </Option>
          );
        })}
      </Select>
    </DropdownStyleWrapper>
  );
};

// EXPORT
// ================================================================
export default PicturesSelectInput;
