import styled from "styled-components";
import { useState } from "react";
import {
  GetManyUsersDocument,
  useCreateSuperAdminUserMutation,
  useDeleteOneSuperAdminUserMutation,
  useGetManyUsersQuery,
  useResendInviteEmailMutation,
  UserProfile,
  UserTypeEnum,
} from "generated/graphql";
import Table from "components/common/Table";
import Tooltip from "components/common/Tooltip";
import message from "components/common/message";
import Drawer from "components/common/Drawer";
import Caption from "components/text/Caption";
import Button from "components/common/Button";
import PageTitle from "components/text/PageTitle";
import ButtonText from "components/common/ButtonText";
import Popconfirm from "components/common/Popconfirm";
import moment from "moment";
import PageContainer from "components/common/PageContainer";
import notification from "components/common/Notification";
// LIB
import theme from "lib/theme";
import UserForm from "components/forms/UserForm";
import { useWindowWidth } from "@react-hook/window-size";

const ActionsBtn = styled.button`
  background: transparent;
  border: 0px;
  color: ${(p) => p.theme.colors.text};
  cursor: pointer;
  padding: 0px;
  &:focus {
    outline: 0;
  }
  &:hover {
    color: ${(p) => p.theme.colors.text2};
  }
`;

export default function AdminUsers() {
  const screenWidth = useWindowWidth();

  const [deleteOneSuperAdminUserMutation] =
    useDeleteOneSuperAdminUserMutation();
  const { data, loading, error } = useGetManyUsersQuery({
    variables: {
      userType: UserTypeEnum.SuperAdmin,
    },
  });
  const [addNew, setAddNew] = useState<boolean>(false);
  const [resendInviteEmailMutation, { loading: resendingInvite }] =
    useResendInviteEmailMutation();
  const [createNewUserMutation, { loading: creating }] =
    useCreateSuperAdminUserMutation();

  if (loading) return <Caption>Loading...</Caption>;

  if (error) return <Caption>Error...</Caption>;

  const handleCreateUser = async (values: any) => {
    try {
      await createNewUserMutation({
        variables: {
          params: {
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
          },
        },
        refetchQueries: [
          {
            query: GetManyUsersDocument,
            variables: {
              userType: UserTypeEnum.SuperAdmin,
            },
          },
        ],
      });
      setAddNew(false);
      message.success("New user added");
    } catch (err: any) {
      notification.error({
        message: "Something went wrong",
        description: err?.message?.includes("E11000")
          ? `a user with that email already exists`
          : err?.message,
        duration: 6,
      });
      console.log(err);
    }
  };

  const columns = [
    ...(screenWidth > 415
      ? [
          {
            key: "id",
            title: "ID",
            render: (record: UserProfile) => record.id,
          },
        ]
      : []),
    {
      title: "Email",
      render: (record: UserProfile) => record.email,
    },
    ...(screenWidth > 415
      ? [
          {
            title: "Invite",
            width: "22.5%",
            render: ({
              id,
              acceptedInvite,
              recentlySentInvite,
              deactivated,
            }: UserProfile) => {
              if (deactivated) return null;

              if (recentlySentInvite) {
                return `Sent ${moment(parseInt(recentlySentInvite)).fromNow()}`;
              }

              return acceptedInvite ? (
                "Accepted"
              ) : (
                <Tooltip title="send another invite email">
                  <ActionsBtn
                    disabled={resendingInvite}
                    style={{ color: theme.colors.primary4, fontWeight: 600 }}
                    onClick={async () => {
                      try {
                        if (!id) return;
                        await resendInviteEmailMutation({
                          variables: {
                            userId: id,
                          },
                          refetchQueries: [
                            {
                              query: GetManyUsersDocument,
                              variables: {
                                userType: UserTypeEnum.SuperAdmin,
                              },
                            },
                          ],
                        });
                        message.success("Invite resent");
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  >
                    {!resendingInvite ? "Resend Invite" : "Sending..."}
                  </ActionsBtn>
                </Tooltip>
              );
            },
          },
        ]
      : []),
    {
      title: "Status",
      render: (record: UserProfile) =>
        record.deactivated ? "deactivated" : "active",
    },
    ...(screenWidth > 415
      ? [
          {
            title: "Actions",
            render: (record: UserProfile) => {
              if (record.deactivated) return null;
              return (
                <Popconfirm
                  title="Are you sure you want to deactivate this user?"
                  okText="Yes"
                  onConfirm={async () => {
                    try {
                      if (!record?.id) return;
                      await deleteOneSuperAdminUserMutation({
                        variables: {
                          userId: record?.id,
                        },
                        refetchQueries: [
                          {
                            query: GetManyUsersDocument,
                            variables: {
                              userType: UserTypeEnum.SuperAdmin,
                            },
                          },
                        ],
                      });
                      message.success(`${record.email} has been deactivated`);
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  <ButtonText>Deactivate</ButtonText>
                </Popconfirm>
              );
            },
          },
        ]
      : []),
  ];
  return (
    <PageContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 32,
        }}
      >
        <PageTitle>Admin Users</PageTitle>
        <Button onClick={() => setAddNew(true)} style={{ marginBottom: 16 }}>
          + Admin User
        </Button>
      </div>
      <Table
        dataSource={data?.getManyUsers as any}
        columns={columns}
        scroll={screenWidth > 600 ? {} : { x: 1300 }}
      />
      <Drawer
        title="Create New Admin"
        placement="right"
        onClose={() => setAddNew(false)}
        visible={addNew ? true : false}
        height={200}
        getContainer={false}
        style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      >
        <UserForm
          onSubmit={handleCreateUser}
          onCancel={() => setAddNew(false)}
          loading={creating}
        />
      </Drawer>
    </PageContainer>
  );
}
