import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

export default function useUrlChange() {
  const location = useLocation();
  const history = useHistory();

  const onUrlChange = (newValues: any, baseUrl?: string) => {
    const existing = queryString.parse(location.search, {
      arrayFormat: "index",
    });
    let newUrl = {
      ...existing,
      ...newValues, // object
    };

    // the user of onUrlChange can pass in a baseUrl, or it will default to the curent base url
    history.push({
      pathname: baseUrl || location.pathname,
      search: `?${queryString.stringify(newUrl, {
        arrayFormat: "index",
      })}`,
    });
  };

  return {
    onUrlChange,
    query: queryString.parse(location.search, {
      arrayFormat: "index",
    }),
  };
}
