import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type Attachment = {
  __typename?: "Attachment";
  id: Scalars["ID"];
  url?: Maybe<Scalars["String"]>;
  attachmentType: AttachmentTypeEnum;
  notes?: Maybe<Scalars["String"]>;
  incomeSource?: Maybe<Scalars["String"]>;
  incomeType?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  episode?: Maybe<Scalars["String"]>;
  incomeAmount?: Maybe<Scalars["String"]>;
  entry?: Maybe<Entry>;
  filename?: Maybe<Scalars["String"]>;
  picture?: Maybe<Picture>;
  user?: Maybe<UserProfile>;
  createdAt?: Maybe<Scalars["String"]>;
};

export type AttachmentInput = {
  /** The S3 Key for this attachment */
  key?: Maybe<Scalars["String"]>;
  attachmentType?: Maybe<AttachmentTypeEnum>;
  userId?: Maybe<Scalars["ID"]>;
  pictureId?: Maybe<Scalars["ID"]>;
  episode?: Maybe<Scalars["String"]>;
  season?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  incomeSource?: Maybe<Scalars["String"]>;
  incomeType?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  /** The gross amount (if an income attachment), in cents */
  incomeAmount?: Maybe<Scalars["Int"]>;
};

export type AttachmentQueryPaginationOptions = {
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type AttachmentQuerySortOptions = {
  order?: Maybe<SortOrderEnum>;
  sortBy?: Maybe<AttachmentSortByEnum>;
};

export enum AttachmentSortByEnum {
  AttachmentType = "attachmentType",
  Episode = "episode",
  IncomeSource = "incomeSource",
  IncomeType = "incomeType",
  Date = "date",
  IncomeAmount = "incomeAmount",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  Picture = "picture",
}

export enum AttachmentTypeEnum {
  Income = "income",
  CallSheet = "call_sheet",
  Contract = "contract",
  ExhibitG = "exhibit_g",
  Other = "other",
  Image = "image",
  Video = "video",
}

export type AttachmentsResponse = {
  __typename?: "AttachmentsResponse";
  results?: Maybe<Array<Attachment>>;
  count?: Maybe<Scalars["Int"]>;
};

export type AuthenticateParamsInput = {
  access_token?: Maybe<Scalars["String"]>;
  access_token_secret?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  user?: Maybe<UserInput>;
  code?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
};

export type CreateNewUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type CreateUserInput = {
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
};

export type CreateUserResult = {
  __typename?: "CreateUserResult";
  userId?: Maybe<Scalars["ID"]>;
  loginResult?: Maybe<LoginResult>;
};

export type EmailRecord = {
  __typename?: "EmailRecord";
  address?: Maybe<Scalars["String"]>;
  verified?: Maybe<Scalars["Boolean"]>;
};

export type EntriesResponse = {
  __typename?: "EntriesResponse";
  results?: Maybe<Array<Entry>>;
  count?: Maybe<Scalars["Int"]>;
};

export type Entry = {
  __typename?: "Entry";
  id: Scalars["ID"];
  picture?: Maybe<Picture>;
  weekEnding?: Maybe<Scalars["String"]>;
  weekStarting?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  owed?: Maybe<Scalars["String"]>;
  entries?: Maybe<Scalars["Int"]>;
  paymentStatus: EntryPaymentStatusEnum;
  attachments?: Maybe<Array<Attachment>>;
  incomes?: Maybe<Array<Attachment>>;
  workDates?: Maybe<Array<Maybe<Scalars["String"]>>>;
  daysWorked?: Maybe<Scalars["Int"]>;
  dueDate?: Maybe<Scalars["Date"]>;
  paid?: Maybe<Scalars["String"]>;
  episode?: Maybe<Scalars["String"]>;
  daysLate?: Maybe<Scalars["Int"]>;
  info?: Maybe<Scalars["String"]>;
};

export enum EntryPaymentStatusEnum {
  Unpaid = "unpaid",
  PaidIncorrectly = "paid_incorrectly",
  ClaimFiled = "claim_filed",
  Paid = "paid",
  MarkedDoneByUser = "marked_done_by_user",
}

export type EntryQueryPaginationOptions = {
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type EntryQuerySortOptions = {
  sortBy?: Maybe<EntrySortByEnum>;
  order?: Maybe<SortOrderEnum>;
};

export enum EntrySortByEnum {
  Picture = "picture",
  DaysLate = "daysLate",
  DaysWorked = "daysWorked",
  PaymentStatus = "paymentStatus",
  UpdatedAt = "updatedAt",
  CreatedAt = "createdAt",
  Owed = "owed",
  Paid = "paid",
  WeekStarting = "weekStarting",
}

export type EntryUploadInput = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  rowId?: Maybe<Scalars["String"]>;
  /** Total pay in cents */
  totalPay?: Maybe<Scalars["Int"]>;
  weekEnding?: Maybe<Scalars["String"]>;
  weekStarting?: Maybe<Scalars["String"]>;
  showName?: Maybe<Scalars["String"]>;
  timeAndAHalfMoney?: Maybe<Scalars["String"]>;
  dailyDoubleTimeMoney?: Maybe<Scalars["String"]>;
  travelTimeMoney?: Maybe<Scalars["String"]>;
  otherDoubleTimeMoney?: Maybe<Scalars["String"]>;
  characterName?: Maybe<Scalars["String"]>;
  workDates?: Maybe<Array<Maybe<Scalars["String"]>>>;
  daysWorked?: Maybe<Scalars["String"]>;
  hash?: Maybe<Scalars["String"]>;
  stringify?: Maybe<Scalars["String"]>;
  episode?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<Scalars["Date"]>;
};

export type ImpersonateReturn = {
  __typename?: "ImpersonateReturn";
  authorized?: Maybe<Scalars["Boolean"]>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export type ImpersonationUserIdentityInput = {
  userId?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type LoginResult = {
  __typename?: "LoginResult";
  sessionId?: Maybe<Scalars["String"]>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export enum MultipartUploadCompleteEnum {
  Complete = "complete",
  Abort = "abort",
}

export type MultipartUploadPartInput = {
  ETag: Scalars["String"];
  PartNumber: Scalars["Int"];
};

export type Mutation = {
  __typename?: "Mutation";
  deleteOneAttachment?: Maybe<MutationResponse>;
  deactivateOneUser?: Maybe<MutationResponse>;
  createSuperAdminUser?: Maybe<UserProfile>;
  createOneCustomerUser?: Maybe<UserProfile>;
  /** Resend invite email */
  resendInviteEmail?: Maybe<MutationResponse>;
  deleteOneSuperAdminUser?: Maybe<MutationResponse>;
  getPresignedUploadUrl?: Maybe<PresignedUploadResponse>;
  uploadManyEntries?: Maybe<MutationResponse>;
  createOneAttachment?: Maybe<Attachment>;
  /** This will actually update more than one entry, since entries (from the frontend perspective) are one entry, but the backend is actually grouping several 'raw entries' into one */
  updateOneEntryPaymentStatus?: Maybe<Entry>;
  createOnePicture?: Maybe<Picture>;
  getPresignedUploadMultiPart?: Maybe<PresignedMultipartResult>;
  completeMultiUpload?: Maybe<MutationResponse>;
  createUser?: Maybe<CreateUserResult>;
  verifyEmail?: Maybe<Scalars["Boolean"]>;
  resetPassword?: Maybe<LoginResult>;
  sendVerificationEmail?: Maybe<Scalars["Boolean"]>;
  sendResetPasswordEmail?: Maybe<Scalars["Boolean"]>;
  addEmail?: Maybe<Scalars["Boolean"]>;
  changePassword?: Maybe<Scalars["Boolean"]>;
  twoFactorSet?: Maybe<Scalars["Boolean"]>;
  twoFactorUnset?: Maybe<Scalars["Boolean"]>;
  impersonate?: Maybe<ImpersonateReturn>;
  refreshTokens?: Maybe<LoginResult>;
  logout?: Maybe<Scalars["Boolean"]>;
  authenticate?: Maybe<LoginResult>;
  verifyAuthentication?: Maybe<Scalars["Boolean"]>;
};

export type MutationDeleteOneAttachmentArgs = {
  attachmentId: Scalars["ID"];
};

export type MutationDeactivateOneUserArgs = {
  userId: Scalars["ID"];
};

export type MutationCreateSuperAdminUserArgs = {
  params: CreateNewUserInput;
};

export type MutationCreateOneCustomerUserArgs = {
  params: CreateNewUserInput;
};

export type MutationResendInviteEmailArgs = {
  userId: Scalars["ID"];
};

export type MutationDeleteOneSuperAdminUserArgs = {
  userId: Scalars["ID"];
};

export type MutationGetPresignedUploadUrlArgs = {
  contentType: Scalars["String"];
  path: Scalars["String"];
};

export type MutationUploadManyEntriesArgs = {
  data?: Maybe<Array<EntryUploadInput>>;
};

export type MutationCreateOneAttachmentArgs = {
  params: AttachmentInput;
};

export type MutationUpdateOneEntryPaymentStatusArgs = {
  entryId: Scalars["ID"];
  paymentStatus: EntryPaymentStatusEnum;
};

export type MutationCreateOnePictureArgs = {
  params?: Maybe<PictureInput>;
};

export type MutationGetPresignedUploadMultiPartArgs = {
  contentType: Scalars["String"];
  parts: Scalars["Int"];
  path: Scalars["String"];
};

export type MutationCompleteMultiUploadArgs = {
  type: MultipartUploadCompleteEnum;
  uploadId: Scalars["String"];
  key: Scalars["String"];
  parts?: Maybe<Array<MultipartUploadPartInput>>;
};

export type MutationCreateUserArgs = {
  user: CreateUserInput;
};

export type MutationVerifyEmailArgs = {
  token: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  token: Scalars["String"];
  newPassword: Scalars["String"];
};

export type MutationSendVerificationEmailArgs = {
  email: Scalars["String"];
};

export type MutationSendResetPasswordEmailArgs = {
  email: Scalars["String"];
};

export type MutationAddEmailArgs = {
  newEmail: Scalars["String"];
};

export type MutationChangePasswordArgs = {
  oldPassword: Scalars["String"];
  newPassword: Scalars["String"];
};

export type MutationTwoFactorSetArgs = {
  secret: TwoFactorSecretKeyInput;
  code: Scalars["String"];
};

export type MutationTwoFactorUnsetArgs = {
  code: Scalars["String"];
};

export type MutationImpersonateArgs = {
  accessToken: Scalars["String"];
  impersonated: ImpersonationUserIdentityInput;
};

export type MutationRefreshTokensArgs = {
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
};

export type MutationAuthenticateArgs = {
  serviceName: Scalars["String"];
  params: AuthenticateParamsInput;
};

export type MutationVerifyAuthenticationArgs = {
  serviceName: Scalars["String"];
  params: AuthenticateParamsInput;
};

export type MutationResponse = {
  __typename?: "MutationResponse";
  success?: Maybe<Scalars["Boolean"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Picture = {
  __typename?: "Picture";
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

export type PictureInput = {
  title?: Maybe<Scalars["String"]>;
};

export type PicturesResponse = {
  __typename?: "PicturesResponse";
  results?: Maybe<Array<Picture>>;
  count?: Maybe<Scalars["Int"]>;
};

export type PresignedMultipartResult = {
  __typename?: "PresignedMultipartResult";
  key: Scalars["String"];
  uploadId: Scalars["String"];
  urls?: Maybe<Array<Scalars["String"]>>;
};

export type PresignedUploadResponse = {
  __typename?: "PresignedUploadResponse";
  url?: Maybe<Scalars["String"]>;
  /** Stringified object */
  fields?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  currentUser?: Maybe<UserProfile>;
  getManyUsers?: Maybe<Array<Maybe<UserProfile>>>;
  getManyPictures?: Maybe<PicturesResponse>;
  getManyEntries?: Maybe<EntriesResponse>;
  getManyAttachments?: Maybe<AttachmentsResponse>;
  twoFactorSecret?: Maybe<TwoFactorSecretKey>;
  getUser?: Maybe<User>;
};

export type QueryGetManyUsersArgs = {
  searchText?: Maybe<Scalars["String"]>;
  userType?: Maybe<UserTypeEnum>;
  deactivated?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetManyPicturesArgs = {
  searchText?: Maybe<Scalars["String"]>;
};

export type QueryGetManyEntriesArgs = {
  userId?: Maybe<Scalars["ID"]>;
  pictureIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  paymentStatuses?: Maybe<Array<EntryPaymentStatusEnum>>;
  searchText?: Maybe<Scalars["String"]>;
  episode?: Maybe<Scalars["String"]>;
  pagination?: Maybe<EntryQueryPaginationOptions>;
  sort?: Maybe<EntryQuerySortOptions>;
};

export type QueryGetManyAttachmentsArgs = {
  userId?: Maybe<Scalars["ID"]>;
  pictureIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  episode?: Maybe<Scalars["String"]>;
  searchText?: Maybe<Scalars["String"]>;
  pagination?: Maybe<AttachmentQueryPaginationOptions>;
  sort?: Maybe<AttachmentQuerySortOptions>;
};

export enum SortOrderEnum {
  Asc = "asc",
  Desc = "desc",
}

export type Tokens = {
  __typename?: "Tokens";
  refreshToken?: Maybe<Scalars["String"]>;
  accessToken?: Maybe<Scalars["String"]>;
};

export type TwoFactorSecretKey = {
  __typename?: "TwoFactorSecretKey";
  ascii?: Maybe<Scalars["String"]>;
  base32?: Maybe<Scalars["String"]>;
  hex?: Maybe<Scalars["String"]>;
  qr_code_ascii?: Maybe<Scalars["String"]>;
  qr_code_hex?: Maybe<Scalars["String"]>;
  qr_code_base32?: Maybe<Scalars["String"]>;
  google_auth_qr?: Maybe<Scalars["String"]>;
  otpauth_url?: Maybe<Scalars["String"]>;
};

export type TwoFactorSecretKeyInput = {
  ascii?: Maybe<Scalars["String"]>;
  base32?: Maybe<Scalars["String"]>;
  hex?: Maybe<Scalars["String"]>;
  qr_code_ascii?: Maybe<Scalars["String"]>;
  qr_code_hex?: Maybe<Scalars["String"]>;
  qr_code_base32?: Maybe<Scalars["String"]>;
  google_auth_qr?: Maybe<Scalars["String"]>;
  otpauth_url?: Maybe<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  id: Scalars["ID"];
  emails?: Maybe<Array<EmailRecord>>;
  username?: Maybe<Scalars["String"]>;
};

export type UserInput = {
  id?: Maybe<Scalars["ID"]>;
  email?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** The main User object used for authentication etc */
export type UserProfile = {
  __typename?: "UserProfile";
  id?: Maybe<Scalars["String"]>;
  userType?: Maybe<UserTypeEnum>;
  email?: Maybe<Scalars["String"]>;
  permission?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  acceptedInvite?: Maybe<Scalars["Boolean"]>;
  recentlySentInvite?: Maybe<Scalars["String"]>;
  deactivated?: Maybe<Scalars["Boolean"]>;
};

export enum UserTypeEnum {
  Customer = "customer",
  SuperAdmin = "superAdmin",
}

export type AttachmentFragmentFragment = { __typename?: "Attachment" } & Pick<
  Attachment,
  | "id"
  | "url"
  | "attachmentType"
  | "notes"
  | "episode"
  | "incomeSource"
  | "incomeType"
  | "date"
  | "incomeAmount"
  | "createdAt"
  | "filename"
>;

export type CurrentUserFragmentFragment = { __typename: "UserProfile" } & Pick<
  UserProfile,
  "id" | "userType" | "email" | "firstName" | "lastName"
>;

export type PictureFragmentFragment = { __typename?: "Picture" } & Pick<
  Picture,
  "id" | "title"
>;

export type AuthenticateMutationVariables = Exact<{
  params: AuthenticateParamsInput;
}>;

export type AuthenticateMutation = { __typename?: "Mutation" } & {
  authenticate?: Maybe<
    { __typename?: "LoginResult" } & Pick<LoginResult, "sessionId"> & {
        tokens?: Maybe<
          { __typename?: "Tokens" } & Pick<
            Tokens,
            "refreshToken" | "accessToken"
          >
        >;
      }
  >;
};

export type CompleteMultiUploadMutationVariables = Exact<{
  type: MultipartUploadCompleteEnum;
  uploadId: Scalars["String"];
  key: Scalars["String"];
  parts?: Maybe<Array<MultipartUploadPartInput> | MultipartUploadPartInput>;
}>;

export type CompleteMultiUploadMutation = { __typename?: "Mutation" } & {
  completeMultiUpload?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type CreateOneCustomerUserMutationVariables = Exact<{
  params: CreateNewUserInput;
}>;

export type CreateOneCustomerUserMutation = { __typename?: "Mutation" } & {
  createOneCustomerUser?: Maybe<
    { __typename?: "UserProfile" } & Pick<UserProfile, "id" | "email">
  >;
};

export type CreateOneAttachmentMutationVariables = Exact<{
  params: AttachmentInput;
}>;

export type CreateOneAttachmentMutation = { __typename?: "Mutation" } & {
  createOneAttachment?: Maybe<
    { __typename?: "Attachment" } & Pick<Attachment, "id" | "attachmentType">
  >;
};

export type CreateOnePictureMutationVariables = Exact<{
  params: PictureInput;
}>;

export type CreateOnePictureMutation = { __typename?: "Mutation" } & {
  createOnePicture?: Maybe<
    { __typename?: "Picture" } & Pick<Picture, "id" | "title">
  >;
};

export type CreateSuperAdminUserMutationVariables = Exact<{
  params: CreateNewUserInput;
}>;

export type CreateSuperAdminUserMutation = { __typename?: "Mutation" } & {
  createSuperAdminUser?: Maybe<
    { __typename?: "UserProfile" } & Pick<UserProfile, "id" | "email">
  >;
};

export type DeactivateOneUserMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type DeactivateOneUserMutation = { __typename?: "Mutation" } & {
  deactivateOneUser?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteOneAttachmentMutationVariables = Exact<{
  attachmentId: Scalars["ID"];
}>;

export type DeleteOneAttachmentMutation = { __typename?: "Mutation" } & {
  deleteOneAttachment?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type DeleteOneSuperAdminUserMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type DeleteOneSuperAdminUserMutation = { __typename?: "Mutation" } & {
  deleteOneSuperAdminUser?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type GetPresignedUploadMultiPartMutationVariables = Exact<{
  contentType: Scalars["String"];
  parts: Scalars["Int"];
  path: Scalars["String"];
}>;

export type GetPresignedUploadMultiPartMutation = {
  __typename?: "Mutation";
} & {
  getPresignedUploadMultiPart?: Maybe<
    { __typename?: "PresignedMultipartResult" } & Pick<
      PresignedMultipartResult,
      "key" | "uploadId" | "urls"
    >
  >;
};

export type GetPresignedUploadUrlMutationVariables = Exact<{
  contentType: Scalars["String"];
  path: Scalars["String"];
}>;

export type GetPresignedUploadUrlMutation = { __typename?: "Mutation" } & {
  getPresignedUploadUrl?: Maybe<
    { __typename?: "PresignedUploadResponse" } & Pick<
      PresignedUploadResponse,
      "url" | "fields"
    >
  >;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "logout"
>;

export type ResendInviteEmailMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type ResendInviteEmailMutation = { __typename?: "Mutation" } & {
  resendInviteEmail?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars["String"];
  token: Scalars["String"];
}>;

export type ResetPasswordMutation = { __typename?: "Mutation" } & {
  resetPassword?: Maybe<
    { __typename?: "LoginResult" } & Pick<LoginResult, "sessionId"> & {
        tokens?: Maybe<
          { __typename?: "Tokens" } & Pick<
            Tokens,
            "refreshToken" | "accessToken"
          >
        >;
      }
  >;
};

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type SendResetPasswordEmailMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "sendResetPasswordEmail"
>;

export type UpdateOneEntryPaymentStatusMutationVariables = Exact<{
  entryId: Scalars["ID"];
  paymentStatus: EntryPaymentStatusEnum;
}>;

export type UpdateOneEntryPaymentStatusMutation = {
  __typename?: "Mutation";
} & {
  updateOneEntryPaymentStatus?: Maybe<
    { __typename?: "Entry" } & Pick<Entry, "id" | "paymentStatus">
  >;
};

export type UploadManyEntriesMutationVariables = Exact<{
  data?: Maybe<Array<EntryUploadInput> | EntryUploadInput>;
}>;

export type UploadManyEntriesMutation = { __typename?: "Mutation" } & {
  uploadManyEntries?: Maybe<
    { __typename?: "MutationResponse" } & Pick<MutationResponse, "success">
  >;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { __typename?: "Query" } & {
  currentUser?: Maybe<
    { __typename?: "UserProfile" } & CurrentUserFragmentFragment
  >;
};

export type GetManyAttachmentsQueryVariables = Exact<{
  userId?: Maybe<Scalars["ID"]>;
  pictureIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  episode?: Maybe<Scalars["String"]>;
  searchText?: Maybe<Scalars["String"]>;
  pagination?: Maybe<AttachmentQueryPaginationOptions>;
  sort?: Maybe<AttachmentQuerySortOptions>;
}>;

export type GetManyAttachmentsQuery = { __typename?: "Query" } & {
  getManyAttachments?: Maybe<
    { __typename?: "AttachmentsResponse" } & Pick<
      AttachmentsResponse,
      "count"
    > & {
        results?: Maybe<
          Array<
            { __typename?: "Attachment" } & {
              picture?: Maybe<
                { __typename?: "Picture" } & Pick<Picture, "id" | "title">
              >;
              entry?: Maybe<
                { __typename?: "Entry" } & Pick<
                  Entry,
                  "id" | "weekEnding" | "weekStarting"
                >
              >;
              user?: Maybe<
                { __typename?: "UserProfile" } & Pick<
                  UserProfile,
                  "id" | "firstName" | "lastName" | "email"
                >
              >;
            } & AttachmentFragmentFragment
          >
        >;
      }
  >;
};

export type GetManyEntriesQueryVariables = Exact<{
  userId?: Maybe<Scalars["ID"]>;
  pictureIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  paymentStatuses?: Maybe<
    Array<EntryPaymentStatusEnum> | EntryPaymentStatusEnum
  >;
  searchText?: Maybe<Scalars["String"]>;
  episode?: Maybe<Scalars["String"]>;
  pagination?: Maybe<EntryQueryPaginationOptions>;
  sort?: Maybe<EntryQuerySortOptions>;
}>;

export type GetManyEntriesQuery = { __typename?: "Query" } & {
  getManyEntries?: Maybe<
    { __typename?: "EntriesResponse" } & Pick<EntriesResponse, "count"> & {
        results?: Maybe<
          Array<
            { __typename?: "Entry" } & Pick<
              Entry,
              | "id"
              | "paid"
              | "daysWorked"
              | "workDates"
              | "dueDate"
              | "entries"
              | "weekEnding"
              | "weekStarting"
              | "owed"
              | "updatedAt"
              | "paymentStatus"
              | "episode"
              | "daysLate"
              | "info"
            > & {
                picture?: Maybe<
                  { __typename?: "Picture" } & PictureFragmentFragment
                >;
                attachments?: Maybe<
                  Array<
                    { __typename?: "Attachment" } & AttachmentFragmentFragment
                  >
                >;
                incomes?: Maybe<
                  Array<
                    { __typename?: "Attachment" } & AttachmentFragmentFragment
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type GetManyPicturesQueryVariables = Exact<{
  searchText?: Maybe<Scalars["String"]>;
}>;

export type GetManyPicturesQuery = { __typename?: "Query" } & {
  getManyPictures?: Maybe<
    { __typename?: "PicturesResponse" } & Pick<PicturesResponse, "count"> & {
        results?: Maybe<
          Array<{ __typename?: "Picture" } & PictureFragmentFragment>
        >;
      }
  >;
};

export type GetManyUsersQueryVariables = Exact<{
  searchText?: Maybe<Scalars["String"]>;
  userType?: Maybe<UserTypeEnum>;
  deactivated?: Maybe<Scalars["Boolean"]>;
}>;

export type GetManyUsersQuery = { __typename?: "Query" } & {
  getManyUsers?: Maybe<
    Array<
      Maybe<
        { __typename?: "UserProfile" } & Pick<
          UserProfile,
          | "id"
          | "email"
          | "firstName"
          | "lastName"
          | "acceptedInvite"
          | "recentlySentInvite"
          | "deactivated"
        >
      >
    >
  >;
};

export type RefreshTokensMutationVariables = Exact<{
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
}>;

export type RefreshTokensMutation = { __typename?: "Mutation" } & {
  refreshTokens?: Maybe<
    { __typename?: "LoginResult" } & Pick<LoginResult, "sessionId"> & {
        tokens?: Maybe<
          { __typename?: "Tokens" } & Pick<
            Tokens,
            "refreshToken" | "accessToken"
          >
        >;
      }
  >;
};

export const AttachmentFragmentFragmentDoc = gql`
  fragment attachmentFragment on Attachment {
    id
    url
    attachmentType
    notes
    episode
    incomeSource
    incomeType
    date
    incomeAmount
    createdAt
    filename
  }
`;
export const CurrentUserFragmentFragmentDoc = gql`
  fragment currentUserFragment on UserProfile {
    id
    __typename
    userType
    email
    firstName
    lastName
  }
`;
export const PictureFragmentFragmentDoc = gql`
  fragment pictureFragment on Picture {
    id
    title
  }
`;
export const AuthenticateDocument = gql`
  mutation authenticate($params: AuthenticateParamsInput!) {
    authenticate(serviceName: "password", params: $params) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type AuthenticateMutationFn = Apollo.MutationFunction<
  AuthenticateMutation,
  AuthenticateMutationVariables
>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAuthenticateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateMutation,
    AuthenticateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthenticateMutation,
    AuthenticateMutationVariables
  >(AuthenticateDocument, options);
}
export type AuthenticateMutationHookResult = ReturnType<
  typeof useAuthenticateMutation
>;
export type AuthenticateMutationResult =
  Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<
  AuthenticateMutation,
  AuthenticateMutationVariables
>;
export const CompleteMultiUploadDocument = gql`
  mutation completeMultiUpload(
    $type: MultipartUploadCompleteEnum!
    $uploadId: String!
    $key: String!
    $parts: [MultipartUploadPartInput!]
  ) {
    completeMultiUpload(
      type: $type
      uploadId: $uploadId
      key: $key
      parts: $parts
    ) {
      success
    }
  }
`;
export type CompleteMultiUploadMutationFn = Apollo.MutationFunction<
  CompleteMultiUploadMutation,
  CompleteMultiUploadMutationVariables
>;

/**
 * __useCompleteMultiUploadMutation__
 *
 * To run a mutation, you first call `useCompleteMultiUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteMultiUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeMultiUploadMutation, { data, loading, error }] = useCompleteMultiUploadMutation({
 *   variables: {
 *      type: // value for 'type'
 *      uploadId: // value for 'uploadId'
 *      key: // value for 'key'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useCompleteMultiUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteMultiUploadMutation,
    CompleteMultiUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteMultiUploadMutation,
    CompleteMultiUploadMutationVariables
  >(CompleteMultiUploadDocument, options);
}
export type CompleteMultiUploadMutationHookResult = ReturnType<
  typeof useCompleteMultiUploadMutation
>;
export type CompleteMultiUploadMutationResult =
  Apollo.MutationResult<CompleteMultiUploadMutation>;
export type CompleteMultiUploadMutationOptions = Apollo.BaseMutationOptions<
  CompleteMultiUploadMutation,
  CompleteMultiUploadMutationVariables
>;
export const CreateOneCustomerUserDocument = gql`
  mutation createOneCustomerUser($params: CreateNewUserInput!) {
    createOneCustomerUser(params: $params) {
      id
      email
    }
  }
`;
export type CreateOneCustomerUserMutationFn = Apollo.MutationFunction<
  CreateOneCustomerUserMutation,
  CreateOneCustomerUserMutationVariables
>;

/**
 * __useCreateOneCustomerUserMutation__
 *
 * To run a mutation, you first call `useCreateOneCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneCustomerUserMutation, { data, loading, error }] = useCreateOneCustomerUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOneCustomerUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneCustomerUserMutation,
    CreateOneCustomerUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneCustomerUserMutation,
    CreateOneCustomerUserMutationVariables
  >(CreateOneCustomerUserDocument, options);
}
export type CreateOneCustomerUserMutationHookResult = ReturnType<
  typeof useCreateOneCustomerUserMutation
>;
export type CreateOneCustomerUserMutationResult =
  Apollo.MutationResult<CreateOneCustomerUserMutation>;
export type CreateOneCustomerUserMutationOptions = Apollo.BaseMutationOptions<
  CreateOneCustomerUserMutation,
  CreateOneCustomerUserMutationVariables
>;
export const CreateOneAttachmentDocument = gql`
  mutation createOneAttachment($params: AttachmentInput!) {
    createOneAttachment(params: $params) {
      id
      attachmentType
    }
  }
`;
export type CreateOneAttachmentMutationFn = Apollo.MutationFunction<
  CreateOneAttachmentMutation,
  CreateOneAttachmentMutationVariables
>;

/**
 * __useCreateOneAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateOneAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneAttachmentMutation, { data, loading, error }] = useCreateOneAttachmentMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOneAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneAttachmentMutation,
    CreateOneAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneAttachmentMutation,
    CreateOneAttachmentMutationVariables
  >(CreateOneAttachmentDocument, options);
}
export type CreateOneAttachmentMutationHookResult = ReturnType<
  typeof useCreateOneAttachmentMutation
>;
export type CreateOneAttachmentMutationResult =
  Apollo.MutationResult<CreateOneAttachmentMutation>;
export type CreateOneAttachmentMutationOptions = Apollo.BaseMutationOptions<
  CreateOneAttachmentMutation,
  CreateOneAttachmentMutationVariables
>;
export const CreateOnePictureDocument = gql`
  mutation createOnePicture($params: PictureInput!) {
    createOnePicture(params: $params) {
      id
      title
    }
  }
`;
export type CreateOnePictureMutationFn = Apollo.MutationFunction<
  CreateOnePictureMutation,
  CreateOnePictureMutationVariables
>;

/**
 * __useCreateOnePictureMutation__
 *
 * To run a mutation, you first call `useCreateOnePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnePictureMutation, { data, loading, error }] = useCreateOnePictureMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOnePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOnePictureMutation,
    CreateOnePictureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOnePictureMutation,
    CreateOnePictureMutationVariables
  >(CreateOnePictureDocument, options);
}
export type CreateOnePictureMutationHookResult = ReturnType<
  typeof useCreateOnePictureMutation
>;
export type CreateOnePictureMutationResult =
  Apollo.MutationResult<CreateOnePictureMutation>;
export type CreateOnePictureMutationOptions = Apollo.BaseMutationOptions<
  CreateOnePictureMutation,
  CreateOnePictureMutationVariables
>;
export const CreateSuperAdminUserDocument = gql`
  mutation createSuperAdminUser($params: CreateNewUserInput!) {
    createSuperAdminUser(params: $params) {
      id
      email
    }
  }
`;
export type CreateSuperAdminUserMutationFn = Apollo.MutationFunction<
  CreateSuperAdminUserMutation,
  CreateSuperAdminUserMutationVariables
>;

/**
 * __useCreateSuperAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateSuperAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuperAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuperAdminUserMutation, { data, loading, error }] = useCreateSuperAdminUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateSuperAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSuperAdminUserMutation,
    CreateSuperAdminUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSuperAdminUserMutation,
    CreateSuperAdminUserMutationVariables
  >(CreateSuperAdminUserDocument, options);
}
export type CreateSuperAdminUserMutationHookResult = ReturnType<
  typeof useCreateSuperAdminUserMutation
>;
export type CreateSuperAdminUserMutationResult =
  Apollo.MutationResult<CreateSuperAdminUserMutation>;
export type CreateSuperAdminUserMutationOptions = Apollo.BaseMutationOptions<
  CreateSuperAdminUserMutation,
  CreateSuperAdminUserMutationVariables
>;
export const DeactivateOneUserDocument = gql`
  mutation deactivateOneUser($userId: ID!) {
    deactivateOneUser(userId: $userId) {
      success
    }
  }
`;
export type DeactivateOneUserMutationFn = Apollo.MutationFunction<
  DeactivateOneUserMutation,
  DeactivateOneUserMutationVariables
>;

/**
 * __useDeactivateOneUserMutation__
 *
 * To run a mutation, you first call `useDeactivateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateOneUserMutation, { data, loading, error }] = useDeactivateOneUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeactivateOneUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateOneUserMutation,
    DeactivateOneUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateOneUserMutation,
    DeactivateOneUserMutationVariables
  >(DeactivateOneUserDocument, options);
}
export type DeactivateOneUserMutationHookResult = ReturnType<
  typeof useDeactivateOneUserMutation
>;
export type DeactivateOneUserMutationResult =
  Apollo.MutationResult<DeactivateOneUserMutation>;
export type DeactivateOneUserMutationOptions = Apollo.BaseMutationOptions<
  DeactivateOneUserMutation,
  DeactivateOneUserMutationVariables
>;
export const DeleteOneAttachmentDocument = gql`
  mutation deleteOneAttachment($attachmentId: ID!) {
    deleteOneAttachment(attachmentId: $attachmentId) {
      success
    }
  }
`;
export type DeleteOneAttachmentMutationFn = Apollo.MutationFunction<
  DeleteOneAttachmentMutation,
  DeleteOneAttachmentMutationVariables
>;

/**
 * __useDeleteOneAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteOneAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneAttachmentMutation, { data, loading, error }] = useDeleteOneAttachmentMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDeleteOneAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneAttachmentMutation,
    DeleteOneAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOneAttachmentMutation,
    DeleteOneAttachmentMutationVariables
  >(DeleteOneAttachmentDocument, options);
}
export type DeleteOneAttachmentMutationHookResult = ReturnType<
  typeof useDeleteOneAttachmentMutation
>;
export type DeleteOneAttachmentMutationResult =
  Apollo.MutationResult<DeleteOneAttachmentMutation>;
export type DeleteOneAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneAttachmentMutation,
  DeleteOneAttachmentMutationVariables
>;
export const DeleteOneSuperAdminUserDocument = gql`
  mutation deleteOneSuperAdminUser($userId: ID!) {
    deleteOneSuperAdminUser(userId: $userId) {
      success
    }
  }
`;
export type DeleteOneSuperAdminUserMutationFn = Apollo.MutationFunction<
  DeleteOneSuperAdminUserMutation,
  DeleteOneSuperAdminUserMutationVariables
>;

/**
 * __useDeleteOneSuperAdminUserMutation__
 *
 * To run a mutation, you first call `useDeleteOneSuperAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSuperAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSuperAdminUserMutation, { data, loading, error }] = useDeleteOneSuperAdminUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteOneSuperAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOneSuperAdminUserMutation,
    DeleteOneSuperAdminUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOneSuperAdminUserMutation,
    DeleteOneSuperAdminUserMutationVariables
  >(DeleteOneSuperAdminUserDocument, options);
}
export type DeleteOneSuperAdminUserMutationHookResult = ReturnType<
  typeof useDeleteOneSuperAdminUserMutation
>;
export type DeleteOneSuperAdminUserMutationResult =
  Apollo.MutationResult<DeleteOneSuperAdminUserMutation>;
export type DeleteOneSuperAdminUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteOneSuperAdminUserMutation,
  DeleteOneSuperAdminUserMutationVariables
>;
export const GetPresignedUploadMultiPartDocument = gql`
  mutation getPresignedUploadMultiPart(
    $contentType: String!
    $parts: Int!
    $path: String!
  ) {
    getPresignedUploadMultiPart(
      contentType: $contentType
      parts: $parts
      path: $path
    ) {
      key
      uploadId
      urls
    }
  }
`;
export type GetPresignedUploadMultiPartMutationFn = Apollo.MutationFunction<
  GetPresignedUploadMultiPartMutation,
  GetPresignedUploadMultiPartMutationVariables
>;

/**
 * __useGetPresignedUploadMultiPartMutation__
 *
 * To run a mutation, you first call `useGetPresignedUploadMultiPartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUploadMultiPartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPresignedUploadMultiPartMutation, { data, loading, error }] = useGetPresignedUploadMultiPartMutation({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      parts: // value for 'parts'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useGetPresignedUploadMultiPartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetPresignedUploadMultiPartMutation,
    GetPresignedUploadMultiPartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetPresignedUploadMultiPartMutation,
    GetPresignedUploadMultiPartMutationVariables
  >(GetPresignedUploadMultiPartDocument, options);
}
export type GetPresignedUploadMultiPartMutationHookResult = ReturnType<
  typeof useGetPresignedUploadMultiPartMutation
>;
export type GetPresignedUploadMultiPartMutationResult =
  Apollo.MutationResult<GetPresignedUploadMultiPartMutation>;
export type GetPresignedUploadMultiPartMutationOptions =
  Apollo.BaseMutationOptions<
    GetPresignedUploadMultiPartMutation,
    GetPresignedUploadMultiPartMutationVariables
  >;
export const GetPresignedUploadUrlDocument = gql`
  mutation getPresignedUploadUrl($contentType: String!, $path: String!) {
    getPresignedUploadUrl(contentType: $contentType, path: $path) {
      url
      fields
    }
  }
`;
export type GetPresignedUploadUrlMutationFn = Apollo.MutationFunction<
  GetPresignedUploadUrlMutation,
  GetPresignedUploadUrlMutationVariables
>;

/**
 * __useGetPresignedUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetPresignedUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPresignedUploadUrlMutation, { data, loading, error }] = useGetPresignedUploadUrlMutation({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useGetPresignedUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetPresignedUploadUrlMutation,
    GetPresignedUploadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetPresignedUploadUrlMutation,
    GetPresignedUploadUrlMutationVariables
  >(GetPresignedUploadUrlDocument, options);
}
export type GetPresignedUploadUrlMutationHookResult = ReturnType<
  typeof useGetPresignedUploadUrlMutation
>;
export type GetPresignedUploadUrlMutationResult =
  Apollo.MutationResult<GetPresignedUploadUrlMutation>;
export type GetPresignedUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  GetPresignedUploadUrlMutation,
  GetPresignedUploadUrlMutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const ResendInviteEmailDocument = gql`
  mutation resendInviteEmail($userId: ID!) {
    resendInviteEmail(userId: $userId) {
      success
    }
  }
`;
export type ResendInviteEmailMutationFn = Apollo.MutationFunction<
  ResendInviteEmailMutation,
  ResendInviteEmailMutationVariables
>;

/**
 * __useResendInviteEmailMutation__
 *
 * To run a mutation, you first call `useResendInviteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteEmailMutation, { data, loading, error }] = useResendInviteEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendInviteEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInviteEmailMutation,
    ResendInviteEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendInviteEmailMutation,
    ResendInviteEmailMutationVariables
  >(ResendInviteEmailDocument, options);
}
export type ResendInviteEmailMutationHookResult = ReturnType<
  typeof useResendInviteEmailMutation
>;
export type ResendInviteEmailMutationResult =
  Apollo.MutationResult<ResendInviteEmailMutation>;
export type ResendInviteEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendInviteEmailMutation,
  ResendInviteEmailMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SendResetPasswordEmailDocument = gql`
  mutation sendResetPasswordEmail($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendResetPasswordEmailMutation,
    SendResetPasswordEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendResetPasswordEmailMutation,
    SendResetPasswordEmailMutationVariables
  >(SendResetPasswordEmailDocument, options);
}
export type SendResetPasswordEmailMutationHookResult = ReturnType<
  typeof useSendResetPasswordEmailMutation
>;
export type SendResetPasswordEmailMutationResult =
  Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>;
export const UpdateOneEntryPaymentStatusDocument = gql`
  mutation updateOneEntryPaymentStatus(
    $entryId: ID!
    $paymentStatus: EntryPaymentStatusEnum!
  ) {
    updateOneEntryPaymentStatus(
      entryId: $entryId
      paymentStatus: $paymentStatus
    ) {
      id
      paymentStatus
    }
  }
`;
export type UpdateOneEntryPaymentStatusMutationFn = Apollo.MutationFunction<
  UpdateOneEntryPaymentStatusMutation,
  UpdateOneEntryPaymentStatusMutationVariables
>;

/**
 * __useUpdateOneEntryPaymentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOneEntryPaymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneEntryPaymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneEntryPaymentStatusMutation, { data, loading, error }] = useUpdateOneEntryPaymentStatusMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      paymentStatus: // value for 'paymentStatus'
 *   },
 * });
 */
export function useUpdateOneEntryPaymentStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneEntryPaymentStatusMutation,
    UpdateOneEntryPaymentStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOneEntryPaymentStatusMutation,
    UpdateOneEntryPaymentStatusMutationVariables
  >(UpdateOneEntryPaymentStatusDocument, options);
}
export type UpdateOneEntryPaymentStatusMutationHookResult = ReturnType<
  typeof useUpdateOneEntryPaymentStatusMutation
>;
export type UpdateOneEntryPaymentStatusMutationResult =
  Apollo.MutationResult<UpdateOneEntryPaymentStatusMutation>;
export type UpdateOneEntryPaymentStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOneEntryPaymentStatusMutation,
    UpdateOneEntryPaymentStatusMutationVariables
  >;
export const UploadManyEntriesDocument = gql`
  mutation uploadManyEntries($data: [EntryUploadInput!]) {
    uploadManyEntries(data: $data) {
      success
    }
  }
`;
export type UploadManyEntriesMutationFn = Apollo.MutationFunction<
  UploadManyEntriesMutation,
  UploadManyEntriesMutationVariables
>;

/**
 * __useUploadManyEntriesMutation__
 *
 * To run a mutation, you first call `useUploadManyEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadManyEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadManyEntriesMutation, { data, loading, error }] = useUploadManyEntriesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUploadManyEntriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadManyEntriesMutation,
    UploadManyEntriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadManyEntriesMutation,
    UploadManyEntriesMutationVariables
  >(UploadManyEntriesDocument, options);
}
export type UploadManyEntriesMutationHookResult = ReturnType<
  typeof useUploadManyEntriesMutation
>;
export type UploadManyEntriesMutationResult =
  Apollo.MutationResult<UploadManyEntriesMutation>;
export type UploadManyEntriesMutationOptions = Apollo.BaseMutationOptions<
  UploadManyEntriesMutation,
  UploadManyEntriesMutationVariables
>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...currentUserFragment
    }
  }
  ${CurrentUserFragmentFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const GetManyAttachmentsDocument = gql`
  query getManyAttachments(
    $userId: ID
    $pictureIds: [ID!]
    $episode: String
    $searchText: String
    $pagination: AttachmentQueryPaginationOptions
    $sort: AttachmentQuerySortOptions
  ) {
    getManyAttachments(
      userId: $userId
      pictureIds: $pictureIds
      episode: $episode
      searchText: $searchText
      pagination: $pagination
      sort: $sort
    ) {
      results {
        ...attachmentFragment
        picture {
          id
          title
        }
        entry {
          id
          weekEnding
          weekStarting
        }
        user {
          id
          firstName
          lastName
          email
        }
      }
      count
    }
  }
  ${AttachmentFragmentFragmentDoc}
`;

/**
 * __useGetManyAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetManyAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyAttachmentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      pictureIds: // value for 'pictureIds'
 *      episode: // value for 'episode'
 *      searchText: // value for 'searchText'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetManyAttachmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyAttachmentsQuery,
    GetManyAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManyAttachmentsQuery,
    GetManyAttachmentsQueryVariables
  >(GetManyAttachmentsDocument, options);
}
export function useGetManyAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyAttachmentsQuery,
    GetManyAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyAttachmentsQuery,
    GetManyAttachmentsQueryVariables
  >(GetManyAttachmentsDocument, options);
}
export type GetManyAttachmentsQueryHookResult = ReturnType<
  typeof useGetManyAttachmentsQuery
>;
export type GetManyAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetManyAttachmentsLazyQuery
>;
export type GetManyAttachmentsQueryResult = Apollo.QueryResult<
  GetManyAttachmentsQuery,
  GetManyAttachmentsQueryVariables
>;
export const GetManyEntriesDocument = gql`
  query getManyEntries(
    $userId: ID
    $pictureIds: [ID!]
    $paymentStatuses: [EntryPaymentStatusEnum!]
    $searchText: String
    $episode: String
    $pagination: EntryQueryPaginationOptions
    $sort: EntryQuerySortOptions
  ) {
    getManyEntries(
      userId: $userId
      pictureIds: $pictureIds
      paymentStatuses: $paymentStatuses
      searchText: $searchText
      episode: $episode
      pagination: $pagination
      sort: $sort
    ) {
      results {
        id
        picture {
          ...pictureFragment
        }
        attachments {
          ...attachmentFragment
        }
        incomes {
          ...attachmentFragment
        }
        paid
        daysWorked
        workDates
        dueDate
        entries
        weekEnding
        weekStarting
        owed
        updatedAt
        paymentStatus
        episode
        daysLate
        info
      }
      count
    }
  }
  ${PictureFragmentFragmentDoc}
  ${AttachmentFragmentFragmentDoc}
`;

/**
 * __useGetManyEntriesQuery__
 *
 * To run a query within a React component, call `useGetManyEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyEntriesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      pictureIds: // value for 'pictureIds'
 *      paymentStatuses: // value for 'paymentStatuses'
 *      searchText: // value for 'searchText'
 *      episode: // value for 'episode'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetManyEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyEntriesQuery,
    GetManyEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyEntriesQuery, GetManyEntriesQueryVariables>(
    GetManyEntriesDocument,
    options
  );
}
export function useGetManyEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyEntriesQuery,
    GetManyEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyEntriesQuery, GetManyEntriesQueryVariables>(
    GetManyEntriesDocument,
    options
  );
}
export type GetManyEntriesQueryHookResult = ReturnType<
  typeof useGetManyEntriesQuery
>;
export type GetManyEntriesLazyQueryHookResult = ReturnType<
  typeof useGetManyEntriesLazyQuery
>;
export type GetManyEntriesQueryResult = Apollo.QueryResult<
  GetManyEntriesQuery,
  GetManyEntriesQueryVariables
>;
export const GetManyPicturesDocument = gql`
  query getManyPictures($searchText: String) {
    getManyPictures(searchText: $searchText) {
      results {
        ...pictureFragment
      }
      count
    }
  }
  ${PictureFragmentFragmentDoc}
`;

/**
 * __useGetManyPicturesQuery__
 *
 * To run a query within a React component, call `useGetManyPicturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyPicturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyPicturesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetManyPicturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyPicturesQuery,
    GetManyPicturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyPicturesQuery, GetManyPicturesQueryVariables>(
    GetManyPicturesDocument,
    options
  );
}
export function useGetManyPicturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyPicturesQuery,
    GetManyPicturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManyPicturesQuery,
    GetManyPicturesQueryVariables
  >(GetManyPicturesDocument, options);
}
export type GetManyPicturesQueryHookResult = ReturnType<
  typeof useGetManyPicturesQuery
>;
export type GetManyPicturesLazyQueryHookResult = ReturnType<
  typeof useGetManyPicturesLazyQuery
>;
export type GetManyPicturesQueryResult = Apollo.QueryResult<
  GetManyPicturesQuery,
  GetManyPicturesQueryVariables
>;
export const GetManyUsersDocument = gql`
  query getManyUsers(
    $searchText: String
    $userType: UserTypeEnum
    $deactivated: Boolean
  ) {
    getManyUsers(
      searchText: $searchText
      userType: $userType
      deactivated: $deactivated
    ) {
      id
      email
      firstName
      lastName
      acceptedInvite
      recentlySentInvite
      deactivated
    }
  }
`;

/**
 * __useGetManyUsersQuery__
 *
 * To run a query within a React component, call `useGetManyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyUsersQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      userType: // value for 'userType'
 *      deactivated: // value for 'deactivated'
 *   },
 * });
 */
export function useGetManyUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManyUsersQuery,
    GetManyUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyUsersQuery, GetManyUsersQueryVariables>(
    GetManyUsersDocument,
    options
  );
}
export function useGetManyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManyUsersQuery,
    GetManyUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyUsersQuery, GetManyUsersQueryVariables>(
    GetManyUsersDocument,
    options
  );
}
export type GetManyUsersQueryHookResult = ReturnType<
  typeof useGetManyUsersQuery
>;
export type GetManyUsersLazyQueryHookResult = ReturnType<
  typeof useGetManyUsersLazyQuery
>;
export type GetManyUsersQueryResult = Apollo.QueryResult<
  GetManyUsersQuery,
  GetManyUsersQueryVariables
>;
export const RefreshTokensDocument = gql`
  mutation refreshTokens($accessToken: String!, $refreshToken: String!) {
    refreshTokens(accessToken: $accessToken, refreshToken: $refreshToken) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type RefreshTokensMutationFn = Apollo.MutationFunction<
  RefreshTokensMutation,
  RefreshTokensMutationVariables
>;

/**
 * __useRefreshTokensMutation__
 *
 * To run a mutation, you first call `useRefreshTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokensMutation, { data, loading, error }] = useRefreshTokensMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokensMutation,
    RefreshTokensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokensMutation,
    RefreshTokensMutationVariables
  >(RefreshTokensDocument, options);
}
export type RefreshTokensMutationHookResult = ReturnType<
  typeof useRefreshTokensMutation
>;
export type RefreshTokensMutationResult =
  Apollo.MutationResult<RefreshTokensMutation>;
export type RefreshTokensMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokensMutation,
  RefreshTokensMutationVariables
>;
