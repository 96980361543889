import { useState } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
// components
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import ErrorBlock from "components/common/ErrorBlock";
// COMPONENTS
import Caption from "components/text/Caption";
import message from "components/common/message";
// GRAPHQL
import {
  useResetPasswordMutation,
  UserProfile,
  UserTypeEnum,
} from "generated/graphql";
// LIB
import logo from "lib/media/logo-horizontal-stacked.png";
import config from "lib/config";
import setLocalStorage from "lib/helpers/setLocalStorage";

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 16px;
`;

const FormContainer = styled.div`
  width: 500px;
  padding: 16px;
  max-width: 100%;
  margin: auto;
  padding-top: 20px;
  padding: 32px 64px;
  border-radius: ${(p) => p.theme.borderRadius}px;
  @media only screen and (max-width: 600px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  align-items: center;
  padding-top: 20px;
  width: 100%;
  display: flex;
  height: 100vh;
  max-width: 100%;
`;

const Logo = styled.img`
  height: 100px;
  margin: auto;
  @media only screen and (max-width: 600px) {
    height: 70px;
  }
`;
const TitleContainer = styled.div`
  min-height: 95px;
  text-align: center;
`;

export default function AuthResetPassword({
  match,
  currentUser,
}: {
  currentUser?: UserProfile;
  match: any;
}) {
  /**
   *
   * HOOKS
   */
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<string[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [resetPassword] = useResetPasswordMutation();
  /**
   *
   * METHODS
   */
  const onSubmit = async (e: any) => {
    try {
      e.preventDefault();
      setErrors([]);

      if (!password) {
        return setErrors(["Please provide a password"]);
      }

      if (!confirmPassword) {
        return setErrors(["Please confirm your password"]);
      }

      if (password !== confirmPassword) {
        return setErrors(["Please make sure you passwords match"]);
      }

      setLoading(true);

      // call mutation to reset password
      const res = await resetPassword({
        variables: {
          newPassword: password,
          token: match.params.token,
        },
      });

      // shoot off a success message
      message.success("Password reset. Logging you in...");

      // pull out the access token and refresh token
      const refreshToken = res.data?.resetPassword?.tokens?.refreshToken;
      const accessToken = res.data?.resetPassword?.tokens?.accessToken;

      if (refreshToken && accessToken) {
        // set items in local storage
        setLocalStorage(config.authTokenName, accessToken);
        setLocalStorage(config.refreshTokenName, refreshToken);
      }

      // reload the page
      window.location.reload();

      //   message.success('Password successfully set');
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setErrors([err.message]);
    }
  };

  /**
   *
   * JSX
   */

  if (currentUser && currentUser.userType === UserTypeEnum.SuperAdmin) {
    return <Redirect to={`/${config.routePrefixes.admin}/home`} />;
  }

  return (
    <Container onSubmit={onSubmit}>
      <FormContainer>
        <TitleContainer>
          <Logo src={logo} />
          <Caption style={{ margin: "16px auto" }}>Reset your password</Caption>
        </TitleContainer>
        <form onSubmit={onSubmit}>
          <FormItem>
            <TextInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="new password"
              type="password"
            />
          </FormItem>
          <FormItem>
            <TextInput
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="confirm new password"
              type="password"
            />
          </FormItem>
          <FormItem>
            <ErrorBlock errors={errors} />
          </FormItem>

          <ButtonContainer>
            <Button
              disabled={loading}
              loading={loading}
              onClick={onSubmit}
              fullWidth
              type="submit"
            >
              Change password
            </Button>
          </ButtonContainer>
        </form>
      </FormContainer>
    </Container>
  );
}
