import { toNumber } from "lodash";
import Input from "react-currency-input-field";
import styled from "styled-components";

const Wrapper = styled.div`
  input {
    color: ${(p) => p.theme.colors.neutral1};
    border: 0px;
    width: 100%;
    height: 56px;
    padding-left: 8px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 2px solid ${(p) => p.theme.colors.neutral9};
    &:focus {
      outline: 0;
      background: unset;
      border: 2px solid ${(p) => p.theme.colors.primary7};
    }
    ::placeholder {
      color: ${(p) => p.theme.colors.neutral7} !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
    background: unset;
    &:disabled {
      cursor: not-allowed;
    }
    /** styles for chrome */
    box-shadow: inset 0 0 0 1000px ${(p) => p.theme.colors.neutral11} !important;
  }
`;

export default function CurrencyInput({
  onChange,
  value = 0,
}: {
  onChange: any;
  value?: number; // the currency in cents
}) {
  return (
    <Wrapper>
      <Input
        id="input-example"
        name="input-name"
        placeholder="Please enter a number"
        defaultValue={value || 0}
        decimalsLimit={2}
        decimalScale={2}
        prefix={"$"}
        onValueChange={(value) => {
          if (!value) return onChange(0);
          const result = (parseFloat(value) * 100).toString();
          return onChange(toNumber(result));
        }}
      />
    </Wrapper>
  );
}
