import { Maybe } from "generated/graphql";

// https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
function validURL(str: any) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

type ErrorType = {
  message?: string;
};

const forceDownloadFile = (
  url?: string | Maybe<string> | undefined,
  filename?: string
) => {
  if (!url) return;

  fetch(url)
    .then(function (t) {
      if (!t.ok) {
        return validURL(url)
          ? window.open(url)
          : console.log("we could not download this atthacment");
      }
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename || "download");
        a.click();
      });
    })
    ?.catch((err: ErrorType) =>
      validURL(url) ? window.open(url) : console.log(err.message)
    ); // if it's a valid URL, open it as a fallback
};

export default forceDownloadFile;
