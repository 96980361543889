import { AttachmentTypeEnum } from "generated/graphql";

export default function entryTypeToLabel(type: AttachmentTypeEnum) {
    if (type === AttachmentTypeEnum.CallSheet) return 'Call Sheet'
    if (type === AttachmentTypeEnum.Contract) return 'Contract'
    if (type === AttachmentTypeEnum.ExhibitG) return 'Exhibit G'
    if (type === AttachmentTypeEnum.Income) return 'Income'
    if (type === AttachmentTypeEnum.Other) return 'Other'
    if (type === AttachmentTypeEnum.Image) return 'Photo'
    if (type === AttachmentTypeEnum.Video) return 'Video'
    return type
}