const theme = {
    // DEFAULTS
    borderRadius: '10px',
    margin: 10,
    padding: 20,
    fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    // COLORS
    colors: {
        // primary
        primary1: '#014D40',
        primary2: '#0C6B58',
        primary3: '#147D64',
        primary4: '#199473',
        primary5: '#27AB83',
        primary6: '#3EBD93',
        primary7: '#65D6AD',
        primary8: '#8EEDC7',
        primary9: '#C6F7E2',
        primary10: '#EFFCF6',
        // Neutrals
        neutral1: '#102A43',
        neutral2: '#243B53',
        neutral3: '#334E68',
        neutral4: '#486581',
        neutral5: '#627D98',
        neutral6: '#829AB1',
        neutral7: '#9FB3C8',
        neutral8: '#BCCCDC',
        neutral9: '#D9E2EC',
        neutral10: '#F0F4F8',
        neutral11: '#FAFCFF',
        // Accent B
        blue1: '#003E6B',
        blue2: '#0A558C',
        blue3: '#0F609B',
        blue4: '#186FAF',
        blue5: '#2680C2',
        blue6: '#4098D7',
        blue7: '#62B0E8',
        blue8: '#84C5F4',
        blue9: '#B6E0FE',
        blue10: '#DCEEFB',
        blue11: '#102a43',
        blue12: '#243b53',
        blue13: '#486581',
        blue14: '#1878BF',
        // Accent B
        supportB1: '#05606E',
        supportB2: '#07818F',
        supportB3: '#099AA4',
        supportB4: '#0FB5BA',
        supportB5: '#1CD4D4',
        supportB6: '#3AE7E1',
        supportB7: '#62F4EB',
        supportB8: '#92FDF2',
        supportB9: '#C1FEF6',
        supportB10: '#E1FCF8',
        supportB11: '#E0FCFF',
        supportB12: '#14919B',

        // Red
        red1: '#610404',
        red2: '#780A0A',
        red3: '#911111',
        red4: '#A61B1B',
        red5: '#BA2525',
        red6: '#D64545',
        red7: '#E66A6A',
        red8: '#F29B9B',
        red9: '#FACDCD',
        red10: '#FFEEEE',
        red11: '#FFE3E3',
        red12: '#E12D39',
        // Yellow
        yellow1: '#513C06',
        yellow2: '#7C5E10',
        yellow3: '#A27C1A',
        yellow4: '#C99A2E',
        yellow5: '#E9B949',
        yellow6: '#F7D070',
        yellow7: '#F9DA8B',
        yellow8: '#F8E3A3',
        yellow9: '#FCEFC7',
        yellow10: '#FFFAEB',
        // success
        success1: '#014807', // darkest
        success2: '#07600E',
        success3: '#0E7817',
        success4: '#0F8613',
        success5: '#18981D',
        success6: '#31B237',
        success7: '#51CA58',
        success8: '#91E697',
        success9: '#C1F2C7',
        success10: '#E3F9E5' // lighest
    },
    breakpoints: {
        medium: '600px',
        small: '600px', // iphone 6/7/8 plus
        xSmall: '375px', // iphone 7/7/8
        xxSmall: '320px', // iphone 5/SE
    },
};

export default theme;