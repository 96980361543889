import { useLocation } from "react-router-dom";
import styled from "styled-components";
// COMPONENTS
import AppNav from "./AppNav";

const Container = styled.div`
  margin: auto;
  width: 1150px;
  max-width: 90%;
  padding-top: 16px;
  padding-bottom: 56px;
  min-height: calc(100vh);
  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
`;

// Background

const Background = styled.div`
  background: ${(p) => p.theme.colors.neutral10};
`;

export default function AdminLayout({ currentUser, children }: any) {
  const location = useLocation();
  return (
    <Background id="admin-background">
      <AppNav pathname={location.pathname} />
      <Container>{children}</Container>
    </Background>
  );
}
