import AttachmentForm from "components/forms/AttachmentForm";
import PageContainer from "components/common/PageContainer";
import { useHistory } from "react-router-dom";
import PageTitle from "components/text/PageTitle";
import {
  AttachmentInput,
  useCreateOneAttachmentMutation,
} from "generated/graphql";
import message from "components/common/message";

export default function AdminNewEntry() {
  const history = useHistory();
  const [createOneAttachmentMutation, { loading }] =
    useCreateOneAttachmentMutation();

  const onSubmit = async (values: AttachmentInput) => {
    try {
      await createOneAttachmentMutation({
        variables: {
          params: {
            ...values,
          },
        },
      });
      message.success("Your attachment was successfully saved");
      history.push("/admin/home");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <PageContainer>
      <PageTitle>New Attachment</PageTitle>
      <AttachmentForm
        loading={loading}
        onSubmit={onSubmit}
        onCancel={() => history?.goBack()}
      />
    </PageContainer>
  );
}
