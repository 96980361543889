import {
  GetManyUsersDocument,
  useCreateOneCustomerUserMutation,
  useDeactivateOneUserMutation,
  useGetManyUsersQuery,
  UserProfile,
  UserTypeEnum,
} from "generated/graphql";
import PageContainer from "components/common/PageContainer";
import Table from "components/common/Table";
import Drawer from "components/common/Drawer";
import PageTitle from "components/text/PageTitle";
import Button from "components/common/Button";
import UserForm from "components/forms/UserForm";
import { useState } from "react";
import message from "components/common/message";
import { useWindowWidth } from "@react-hook/window-size";
import notification from "components/common/Notification";
import ButtonText from "components/common/ButtonText";
import Popconfirm from "components/common/Popconfirm";

export default function AdminUsers() {
  const screenWidth = useWindowWidth();
  const [deactivateOneUserMutation] = useDeactivateOneUserMutation();
  const [addNew, setAddNew] = useState<boolean>(false);
  const [createOneCustomerUserMutation, { loading: creating }] =
    useCreateOneCustomerUserMutation();
  const { data } = useGetManyUsersQuery({
    variables: {
      userType: UserTypeEnum.Customer,
    },
  });

  const columns = [
    ...(screenWidth > 415
      ? [
          {
            title: "First",
            render: (record: UserProfile) => record.firstName,
          },
        ]
      : []),
    {
      title: "Last",
      render: (record: UserProfile) => record.lastName,
    },
    {
      title: "Email",
      render: (record: UserProfile) => record.email,
    },
    {
      title: "Status",
      render: (record: UserProfile) =>
        record.deactivated ? "deactivated" : "active",
    },
    ...(screenWidth > 415
      ? [
          {
            title: "Actions",
            render: (record: UserProfile) => {
              if (record.deactivated) return null;
              return (
                <Popconfirm
                  title="Are you sure you want to deactivate this user?"
                  okText="Yes"
                  onConfirm={async () => {
                    try {
                      if (!record?.id) return;
                      await deactivateOneUserMutation({
                        variables: {
                          userId: record?.id,
                        },
                        refetchQueries: ["getManyUsers"],
                      });
                      message.success(`${record.email} has been deactivated`);
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  <ButtonText>Deactivate</ButtonText>
                </Popconfirm>
              );
            },
          },
        ]
      : []),
  ];

  const handleCreateUser = async (values: any) => {
    try {
      await createOneCustomerUserMutation({
        variables: {
          params: {
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
          },
        },
        refetchQueries: [
          {
            query: GetManyUsersDocument,
            variables: {
              userType: UserTypeEnum.Customer,
            },
          },
        ],
      });
      setAddNew(false);
      message.success("New user added");
    } catch (err: any) {
      notification.error({
        message: "Something went wrong",
        description: err?.message?.includes("E11000")
          ? `a user with that email already exists`
          : err?.message,
        duration: 6,
      });
      console.log(err);
    }
  };

  return (
    <PageContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 32,
        }}
      >
        <PageTitle>Customer Users</PageTitle>
        <Button onClick={() => setAddNew(true)} style={{ marginBottom: 16 }}>
          + Customer
        </Button>
      </div>
      <Table
        dataSource={data?.getManyUsers as any}
        columns={columns}
        rowKey="id"
        scroll={screenWidth > 600 ? {} : { x: 1300 }}
      />
      <Drawer
        title="Create New Customer"
        placement="right"
        onClose={() => setAddNew(false)}
        visible={addNew ? true : false}
      >
        <UserForm
          onSubmit={handleCreateUser}
          onCancel={() => setAddNew(false)}
          loading={creating}
        />
      </Drawer>
    </PageContainer>
  );
}
