import styled from "styled-components";

const BreakTitle = styled.p`
  margin: 0px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.neutral1};
`;

const BreakCaption = styled.p`
  margin: 0px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.neutral6};
`;
const Container = styled.div`
  margin-top: 48px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral10};
  padding-bottom: 8px;
  max-width: 100%;
`;

export default function FormBreak({
  title,
  caption,
  style,
}: {
  title: string;
  caption?: string;
  style?: React.CSSProperties;
}) {
  return (
    <Container style={style}>
      <BreakTitle>{title}</BreakTitle>
      {caption && <BreakCaption>{caption}</BreakCaption>}
    </Container>
  );
}
