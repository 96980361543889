import theme, { GlobalStyle } from "lib/theme";
import { ThemeProvider } from "styled-components";
import AppRoutes from "./pages/index";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import client from "ApolloClient/index";
import ErrorBoundary from "components/common/ErrorBoundaryProps";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <GlobalStyle />
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </ApolloProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
