import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { AttachmentTypeEnum, Maybe } from "generated/graphql";
import entryTypeToLabel from "lib/helpers/entryTypeToLabel";
import DropdownStyleWrapper from "../DropdownStyleWrapper";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const EntryTypeSelectInput = (props: {
  onChange: any;
  value?: Maybe<string>;
  style?: React.CSSProperties;
  placeholder?: string;
}) => {
  const { value, style } = props;

  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        value={value || undefined}
        showArrow={false}
        size="large"
        showSearch
        optionFilterProp="children"
        placeholder={props?.placeholder || "What type of entry is this?"}
        style={{
          width: "100%",
          ...style,
        }}
      >
        {Object.values(AttachmentTypeEnum)?.map((key: any) => {
          return (
            <Option key={key} value={key}>
              {entryTypeToLabel(key as AttachmentTypeEnum)}
            </Option>
          );
        })}
      </Select>
    </DropdownStyleWrapper>
  );
};

// EXPORT
// ================================================================
export default EntryTypeSelectInput;
