import styled from "styled-components";
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Label = styled.label`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary2};
  padding: 8px 16px;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.primary9};
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    background: ${(p) => p.theme.colors.primary8};
    color: ${(p) => p.theme.colors.primary1};
  }
`;

export default function UploadButton({
  loading,
  label,
  id = "compay-upload",
  onChange,
  style,
}: {
  id: string;
  label?: string;
  loading?: boolean;
  onChange: any;
  style?: React.CSSProperties;
}) {
  return (
    <div style={{ display: "inline-block", ...style }}>
      <FileInput
        name={id}
        type="file"
        id={id}
        onChange={onChange}
        disabled={loading}
      />
      <Label htmlFor={id}>
        {!loading ? (
          <>
            <CloudUploadOutlined style={{ marginRight: 6 }} />{" "}
            {label || "Upload New File"}
          </>
        ) : (
          <>
            <LoadingOutlined style={{ marginRight: 6 }} />
            Uploading...
          </>
        )}
      </Label>
    </div>
  );
}
