

export enum NodeEnvironments {
    production = 'production',
    development = 'development',
}

interface Config {
    appName: string,
    companyName: string,
    supportEmail: string,
    GRAPHQL_API: string,
    NODE_ENV: NodeEnvironments,
    authTokenName: string,
    refreshTokenName: string,
    routePrefixes: {
        client: string,
        admin: string
    }
}

const getEnv = (): NodeEnvironments => {
    if (process.env.NODE_ENV === NodeEnvironments.production) return NodeEnvironments.production;
    return NodeEnvironments.development;
}

const config: Config = {
    appName: 'Actors Bookkeeper',
    companyName: 'Actors Bookkeeper, Inc.',
    supportEmail: 'support@actorsbk.com',
    authTokenName: process.env.AUTH_TOKEN_NAME || 'actorsbookkeeper_staging_access_token',
    refreshTokenName:
        process.env.REFRESH_TOKEN_NAME || 'actorsbookkeeper_staging_refresh_token',
    GRAPHQL_API: process.env.REACT_APP_API_HOST || 'http://localhost:8080/graphql',
    NODE_ENV: getEnv(),
    routePrefixes: {
        client: 'app',
        admin: 'admin'
    }
}


export default config