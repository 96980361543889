import UploadButton from "components/common/UploadButton";
import Text from "components/text/Text";
import styled from "styled-components";
import useUploadFile from "lib/hooks/useUploadFile";
import { useState } from "react";
import FormItem from "components/common/FormItem";
import Button from "components/common/Button";
import FormBreak from "components/common/FormBreak";
import Row from "components/common/Row";
import Col from "components/common/Col";
import UserSelectInput from "components/inputs/UserSelectInput";
import EntryTypeSelectInput from "components/inputs/EntryTypeSelectInput";
import TextInput from "components/inputs/TextInput";
import TextAreaInput from "components/inputs/TextAreaInput";
import CurrencyInput from "components/inputs/CurrencyInput";
import PicturesSelectInput from "components/inputs/PicturesSelectInput";
import { AttachmentTypeEnum, useGetManyEntriesQuery } from "generated/graphql";
import moment from "moment";
import DateInput from "components/inputs/DateInput";
import Tooltip from "components/common/Tooltip";
import { omit } from "lodash";
import { CloseCircleFilled } from "@ant-design/icons";
import { useWindowWidth } from "@react-hook/window-size";

const FormContainer = styled.div`
  margin-top: 24px;
  background: #fff;
  padding: 16px;
  width: 700px;
  max-width: 100%;
  border-radius: 5px;
`;

const InputsContainer = styled.div`
  /* padding: 16px; */
  /* border: 1px solid ${({ theme }) => theme.colors.neutral9}; */
  max-width: 100%;
  margin-top: 24px;
`;

const UploadRow = styled.div`
  display: flex;
  align-items: center;
  min-height: 72px;
`;

const ButtonContainer = styled.div`
  margin-top: 32px;
  max-width: 100%;
`;

const EntriesList = ({ pictureId, userId }: any) => {
  const { data } = useGetManyEntriesQuery({
    variables: {
      userId,
      pictureIds: [pictureId],
    },
  });
  return (
    <div style={{ marginTop: 16, paddingLeft: 16 }}>
      <FormBreak
        title="Make sure date matches the correct entry"
        caption=" You'll want to make sure the date field falls between the week start/end of the entry you intend to log this attachment"
        style={{ marginTop: 16 }}
      />
      <ul>
        {data?.getManyEntries?.results?.map((entry) => {
          return (
            <li key={entry.id}>
              <strong> {entry?.picture?.title}</strong> {entry?.weekStarting} -{" "}
              {entry?.weekEnding}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default function EntryForm({
  onSubmit,
  onCancel,
  loading,
}: {
  onCancel: () => void;
  onSubmit: any;
  loading?: boolean;
}) {
  const [uploadFile] = useUploadFile();
  const [values, setValues] = useState<{
    attachmentType?: AttachmentTypeEnum;
    userId?: string;
    pictureId?: string;
    notes?: string;
    episode?: string;
    season?: string;
    attachment?: File;
    date?: string;
    filename?: string;
    // income
    incomeAmount?: number;
    incomeType?: string;
    incomeSource?: string;
  }>({
    date: moment()?.valueOf()?.toString(),
  });

  const [errors, setErrors] = useState<{
    attachmentType?: string;
    userId?: string;
    notes?: string;
    episode?: string;
    season?: string;
    pictureId?: string;
    attachment?: string;
    date?: string;
    // income
    incomeAmount?: string;
    incomeType?: string;
    incomeSource?: string;
  }>({});

  useWindowWidth(); // cause the form to re-render when screensize changes

  const handleSubmit = async () => {
    setErrors({});
    if (!values?.userId) return setErrors({ userId: "This is required" });

    if (!values?.pictureId) return setErrors({ pictureId: "This is required" });

    // if this is an income attachment, we check some additional fields
    if (values?.attachmentType === AttachmentTypeEnum.Income) {
      if (!values?.incomeAmount) {
        return setErrors({ incomeAmount: "This is required" });
      }
      if (!values?.date) {
        return setErrors({ date: "This is required" });
      }
    }

    if (!values?.userId) return setErrors({ userId: "This is required" });

    let key;

    if (values?.attachment) {
      const upload = await uploadFile(
        values?.attachment,
        `attachments/${values.userId}` // the path in s3
      );
      if (!upload) return console.log("Nothing came back from uploadFile");
      key = upload.key;
    }

    onSubmit({
      ...omit(values, ["attachment"]), // remove any fields from local state that we don't need/want to pass up to the parent component
      key,
      filename: values?.attachment?.name,
      date: values?.date?.toString(),
    });
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const onValueChange = (newValues: any) => {
    setErrors({});
    setValues({
      ...values,
      ...newValues,
    });
  };

  console.log({
    values,
  });

  return (
    <>
      <FormContainer>
        {/*  */}
        <FormBreak
          title="General Details"
          caption="Tell us about this entry"
          style={{ marginTop: 16 }}
        />
        <UploadRow>
          <Row gutter={16} align="middle" style={{ width: "100%" }}>
            <Col xs={24} sm={6}>
              <Text style={{ marginRight: 24 }}>Add attachment:</Text>
            </Col>
            <Col xs={24} sm={8}>
              <UploadButton
                id="entry-form-upload"
                style={{ marginTop: 8, marginBottom: 8 }}
                onChange={(e: any) =>
                  onValueChange({ attachment: e?.target?.files?.[0] })
                }
              />
            </Col>
            <Col xs={24} sm={10}>
              {values.attachment && (
                <div
                  style={{
                    marginLeft: 8,
                    marginTop: 8,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text>{values?.attachment?.name}</Text>
                  <Tooltip title="Remove file">
                    <CloseCircleFilled
                      style={{ marginLeft: 16 }}
                      onClick={() => onValueChange({ attachment: null })}
                    />
                  </Tooltip>
                </div>
              )}
            </Col>
          </Row>
        </UploadRow>
        <InputsContainer>
          <FormItem label="Performer Name" error={errors.userId}>
            <UserSelectInput
              onChange={(userId: string) => onValueChange({ userId })}
              style={{ width: 400, maxWidth: "100%" }}
              placeholder="Select a performer"
            />
          </FormItem>
          <FormItem label="Attachment Type" error={errors.attachmentType}>
            <EntryTypeSelectInput
              onChange={(attachmentType: AttachmentTypeEnum) =>
                onValueChange({ attachmentType })
              }
              style={{ width: 400, maxWidth: "100%" }}
            />
          </FormItem>

          {values?.attachmentType === AttachmentTypeEnum.Income && (
            <>
              <FormBreak
                title="Income Details"
                caption="Please add additional details about this income"
              />
              <Row gutter={16}>
                <Col xs={12}>
                  <FormItem label="Income Type" error={errors.incomeType}>
                    <TextInput
                      onChange={(e: any) =>
                        onValueChange({ incomeType: e.target.value })
                      }
                      placeholder="Session fee, residual, reimbursement etc."
                    />
                  </FormItem>
                </Col>
                <Col xs={12}>
                  <FormItem label="Income Source" error={errors.incomeSource}>
                    <TextInput
                      onChange={(e: any) =>
                        onValueChange({ incomeSource: e.target.value })
                      }
                      placeholder="Check, cash, myEP, bank deposit, etc."
                    />
                  </FormItem>
                </Col>
                <Col xs={12}></Col>
                <Col xs={12}>
                  <FormItem label="Gross Amount" error={errors.incomeAmount}>
                    <CurrencyInput
                      onChange={(incomeAmount: any) => {
                        onValueChange({ incomeAmount });
                      }}
                      value={values?.incomeAmount}
                    />
                  </FormItem>
                </Col>
              </Row>
            </>
          )}
          {/* Picture Details */}
          <FormBreak
            title="Project Details"
            caption="Which project was this person working on?"
          />
          <Row gutter={16} style={{ maxWidth: "100%" }}>
            <Col xs={24} sm={18}>
              <FormItem label="Picture Title" error={errors.pictureId}>
                <PicturesSelectInput
                  onChange={(pictureId: string) => onValueChange({ pictureId })}
                  value={values.pictureId}
                />
              </FormItem>
            </Col>
            {/* <Col xs={24} sm={4}>
              <FormItem label="Season">
                <TextInput
                  onChange={(e: any) =>
                    onValueChange({ season: e.target.value })
                  }
                  type="number"
                  min="0"
                />
              </FormItem>
            </Col> */}
            <Col xs={24} sm={6}>
              <FormItem label="Seasion/Episode" hint={`e.g. "203" for S3E03`}>
                <TextInput
                  onChange={(e: any) =>
                    onValueChange({ episode: e.target.value })
                  }
                  type="number"
                  min="0"
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 16 }}>
            <Col sm={24} md={6}>
              <FormItem label="Date" error={errors.date}>
                <DateInput
                  onChange={(date: any) =>
                    onValueChange({ date: date?.valueOf() })
                  }
                  size="large"
                  value={
                    values?.date ? moment(parseInt(values?.date)) : moment()
                  }
                />
              </FormItem>
            </Col>
            <Col sm={24} md={18}>
              {values?.userId && values.pictureId && (
                <EntriesList
                  userId={values?.userId}
                  pictureId={values?.pictureId}
                />
              )}
            </Col>
          </Row>
          <FormBreak
            title="Extra Details"
            caption="Add additional relevant details"
          />
          <FormItem label="Notes">
            <TextAreaInput
              onChange={(e: any) => onValueChange({ notes: e.target.value })}
              rows={4}
            />
          </FormItem>

          <ButtonContainer>
            <Button loading={loading} disabled={loading} onClick={handleSubmit}>
              Save Attachment
            </Button>
          </ButtonContainer>
        </InputsContainer>
      </FormContainer>
    </>
  );
}
