import EntriesTable from "./EntriesTable";
import styled from "styled-components";
import PaymentStatusMultiSelectInput from "components/inputs/PaymentStatusMultiSelectInput";
import UserSelectInput from "components/inputs/UserSelectInput";
import {
  EntryPaymentStatusEnum,
  EntrySortByEnum,
  SortOrderEnum,
  useGetManyEntriesQuery,
} from "generated/graphql";
import PageContainer from "components/common/PageContainer";
import { useState } from "react";
import TextInput from "components/inputs/TextInput";
import useUrlChange from "lib/hooks/useUrlChange";
import { toNumber } from "lodash";

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 8px;
  @media only screen and (max-width: 600px) {
    max-width: 100%;
    display: block;
    margin-top: 0px;
    .ant-select {
      margin-bottom: 16px;
    }
    input {
      margin-bottom: 16px;
    }
  }
`;

const DEFAULT_LIMIT = 10;

export default function AdminHome() {
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [episode, setEpisode] = useState<string | undefined>(undefined);
  const [paymentStatuses, setPaymentStatuses] = useState<
    EntryPaymentStatusEnum[] | undefined
  >(undefined);
  const { onUrlChange, query } = useUrlChange();
  const { skip, limit, currentPage = 1 } = query;
  const [sort, setSort] = useState<
    { field: EntrySortByEnum; order: SortOrderEnum }[]
  >([]);

  const { data, loading } = useGetManyEntriesQuery({
    skip: !userId,
    variables: {
      userId,
      searchText,
      episode,
      paymentStatuses: paymentStatuses?.[0] ? paymentStatuses : undefined,
      // sort: {
      //   order: SortOrderEnum.Asc,
      //   sortBy: EntrySortByEnum.DaysWorked,
      // },
      sort: sort?.[0]
        ? {
            sortBy: sort?.[0]?.field,
            order: sort?.[0]?.order,
          }
        : null,
      pagination: {
        skip: toNumber(skip || 0),
        limit: toNumber(limit || DEFAULT_LIMIT),
      },
    },
    fetchPolicy: "cache-and-network",
  });

  /** We want to reset the table whenever a filter changes */
  const resetOptions = () => {
    onUrlChange({
      currentPage: 1,
      skip: 0,
    });
  };

  return (
    <PageContainer>
      <TopRow>
        <UserSelectInput
          onChange={(value: string) => {
            resetOptions();
            setUserId(value);
          }}
          style={{ width: 400, maxWidth: "100%", marginRight: 8 }}
        />

        <PaymentStatusMultiSelectInput
          onChange={(value: any) => {
            resetOptions();
            setPaymentStatuses(value);
          }}
          style={{ width: 400, maxWidth: "100%", marginRight: 8 }}
        />
        <TextInput
          placeholder="Search Picture..."
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            resetOptions();
            setSearchText(event?.target?.value);
          }}
          style={{ width: 400, maxWidth: "100%", marginRight: 8 }}
        />
        <TextInput
          placeholder="Episode..."
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            resetOptions();
            setEpisode(event?.target?.value);
          }}
          style={{ width: 100, maxWidth: "100%" }}
        />
      </TopRow>
      <p>
        {loading ? "..." : `${data?.getManyEntries?.count || 0} entries`}{" "}
        {!userId && `(Select a user to see results)`}
      </p>
      <EntriesTable
        loading={loading}
        sort={sort}
        dataSource={data?.getManyEntries?.results || []}
        pagination={{
          total: data?.getManyEntries?.count || 0,
          defaultPageSize: toNumber(limit || DEFAULT_LIMIT),
          pageSize: toNumber(limit || DEFAULT_LIMIT),
          current: toNumber(currentPage),
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
        }}
        onChange={(pagination: any, filters: any, sorter: any) => {
          if (sorter?.columnKey) {
            if (!sorter?.order) {
              setSort(sort?.filter((item) => item.field !== sorter?.columnKey));
            } else {
              setSort([
                {
                  field: sorter?.columnKey,
                  order:
                    sorter?.order === "descend"
                      ? SortOrderEnum.Desc
                      : SortOrderEnum.Asc,
                },
              ]);
            }
          }
          onUrlChange({
            currentPage: pagination?.current,
            limit: pagination?.pageSize,
            skip: pagination?.pageSize * (pagination.current - 1),
          });
        }}
      />
    </PageContainer>
  );
}
