import {
  Attachment,
  AttachmentSortByEnum,
  SortOrderEnum,
  useDeleteOneAttachmentMutation,
  useGetManyAttachmentsQuery,
} from "generated/graphql";
import Table from "components/common/Table";
import Tag from "components/common/Tag";
import moment from "moment";
import Tooltip from "components/common/Tooltip";
import message from "components/common/message";
import Popconfirm from "components/common/Popconfirm";
import { CloudDownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import TextInput from "components/inputs/TextInput";
import styled from "styled-components";
import UserSelectInput from "components/inputs/UserSelectInput";
import useUrlChange from "lib/hooks/useUrlChange";
import { toNumber } from "lodash";
import forceDownloadFile from "lib/helpers/forceDownloadFile";
import entryTypeToLabel from "lib/helpers/entryTypeToLabel";

const TopRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 0px;
  margin-bottom: 8px;
  @media only screen and (max-width: 600px) {
    max-width: 100%;
    display: block;
    margin-top: 0px;
    .ant-select {
      margin-bottom: 16px;
    }
    input {
      margin-bottom: 16px;
    }
  }
`;

const DEFAULT_LIMIT = 50;

const DownloadBtn = styled.button`
  border: 0px;
  padding: 0px;
  background-color: transparent;
  color: ${(p) => p.theme.colors.primary3};
  &:hover {
    cursor: pointer;
  }
`;

const getSortOrder = (
  sort: { field: AttachmentSortByEnum; order: SortOrderEnum }[],
  column: AttachmentSortByEnum
): "descend" | "ascend" | undefined => {
  const sortField = sort?.find((item: any) => item.field === column);
  if (!sortField) return undefined;
  return sortField?.order === SortOrderEnum.Desc ? "descend" : "ascend";
};

export default function AdminAttachments() {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [episode, setEpisode] = useState<string | undefined>(undefined);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const { onUrlChange, query } = useUrlChange();
  const { skip, limit, currentPage = 1 } = query;
  const [sort, setSort] = useState<
    { field: AttachmentSortByEnum; order: SortOrderEnum }[]
  >([]);

  const { data, loading } = useGetManyAttachmentsQuery({
    variables: {
      episode,
      searchText,
      userId,
      pagination: {
        skip: toNumber(skip || 0),
        limit: toNumber(limit || DEFAULT_LIMIT),
      },
      sort: sort?.[0]
        ? {
            sortBy: sort?.[0]?.field,
            order: sort?.[0]?.order,
          }
        : null,
    },
  });
  const [deleteOneAttachmentMutation] = useDeleteOneAttachmentMutation();

  const columns = [
    {
      title: "Type",
      key: AttachmentSortByEnum.AttachmentType,
      sortOrder: getSortOrder(sort, AttachmentSortByEnum.AttachmentType),
      sorter: true,
      render: ({ id, attachmentType }: Attachment) => (
        <Tooltip title={id}>{attachmentType}</Tooltip>
      ),
    },
    {
      title: "User",
      render: ({ user }: Attachment) => (
        <Tooltip title={` ${user?.email}`}>
          {user && user?.email && user?.email?.length > 15
            ? user?.email?.slice(0, 15) + "..."
            : user?.email}
        </Tooltip>
      ),
    },
    {
      title: "Picture",
      key: AttachmentSortByEnum.Picture,
      sortOrder: getSortOrder(sort, AttachmentSortByEnum.Picture),
      sorter: true,
      render: ({ picture, episode }: Attachment) => {
        if (!episode) return picture?.title;
        return picture?.title;
        // return `${picture?.title} | ${episode}`;
      },
    },
    {
      title: "Ep.",
      key: AttachmentSortByEnum.Episode,
      sortOrder: getSortOrder(sort, AttachmentSortByEnum.Episode),
      sorter: true,
      render: ({ episode }: Attachment) => {
        return episode;
      },
    },
    {
      title: "Date",
      key: AttachmentSortByEnum.Date,
      sortOrder: getSortOrder(sort, AttachmentSortByEnum.Date),
      sorter: true,
      render: ({ date }: Attachment) =>
        date && moment(parseInt(date))?.format("M/D/YY"),
    },

    {
      title: "Entry Start/End",
      render: ({ entry }: Attachment) =>
        entry ? (
          `${entry?.weekStarting}-${entry?.weekEnding}`
        ) : (
          <Tag color="magenta">No Entry</Tag>
        ),
    },
    {
      title: "Created",
      key: AttachmentSortByEnum.CreatedAt,
      sortOrder: getSortOrder(sort, AttachmentSortByEnum.CreatedAt),
      sorter: true,
      render: ({ createdAt }: Attachment) =>
        createdAt && moment(parseInt(createdAt))?.format("M/D/YY"),
    },
    {
      title: "",
      render: ({ id, url, filename, attachmentType }: Attachment) => {
        return (
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: 24 }}>
              {url && (
                <Tooltip
                  title={`Click to download ${entryTypeToLabel(
                    attachmentType
                  )}${filename ? `: ${filename}` : ""}`}
                >
                  <DownloadBtn
                    onClick={() => {
                      forceDownloadFile(url, filename || "");
                    }}
                  >
                    {" "}
                    <CloudDownloadOutlined style={{ fontSize: 22 }} />
                  </DownloadBtn>
                </Tooltip>
              )}
            </div>
            <div>
              {" "}
              <Popconfirm
                title="Are you sure you want to delete this attachment?"
                okText="yes"
                onConfirm={async () => {
                  try {
                    await deleteOneAttachmentMutation({
                      variables: {
                        attachmentId: id,
                      },
                      refetchQueries: ["getManyAttachments"],
                    });
                    message.success("Attachment deleted");
                  } catch (err: any) {
                    message.error(err?.message);
                  }
                }}
              >
                <DeleteOutlined style={{ fontSize: 22, marginLeft: 24 }} />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <TopRow>
        <UserSelectInput
          onChange={(value: string) => setUserId(value)}
          style={{ width: 400, maxWidth: "100%", marginRight: 8 }}
        />
        <TextInput
          placeholder="Search Picture..."
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setSearchText(event?.target?.value)}
          style={{ width: 400, maxWidth: "100%", marginRight: 8 }}
        />
        <TextInput
          placeholder="Episode..."
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setEpisode(event?.target?.value)}
          style={{ width: 100, maxWidth: "100%", marginRight: 8 }}
        />
      </TopRow>
      <p>
        {" "}
        {loading ? "..." : `${data?.getManyAttachments?.count} attachments`}
      </p>

      <Table
        rowKey={"id"}
        columns={columns}
        rowClassName={
          (_, index) => (Boolean(index % 2) ? "table-row-bg" : "") // clickup=1rp4pd3
        }
        style={{ maxWidth: 1400 }}
        loading={loading}
        pagination={{
          total: data?.getManyAttachments?.count || 0,
          defaultPageSize: toNumber(limit || DEFAULT_LIMIT),
          pageSize: toNumber(limit || DEFAULT_LIMIT),
          current: toNumber(currentPage),
        }}
        onChange={(pagination: any, filters: any, sorter: any) => {
          if (sorter?.columnKey) {
            if (!sorter?.order) {
              setSort(sort?.filter((item) => item.field !== sorter?.columnKey));
            } else {
              setSort([
                {
                  field: sorter?.columnKey,
                  order:
                    sorter?.order === "descend"
                      ? SortOrderEnum.Desc
                      : SortOrderEnum.Asc,
                },
              ]);
            }
          }
          onUrlChange({
            currentPage: pagination?.current,
            limit: pagination?.pageSize,
            skip: pagination?.pageSize * (pagination.current - 1),
          });
        }}
        dataSource={data?.getManyAttachments?.results || []}
      />
    </>
  );
}
