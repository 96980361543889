import styled from "styled-components";
// COMPONENTS
import { UserOutlined } from "@ant-design/icons";
import { Menu, Dropdown, message } from "antd";
import { useLogoutMutation } from "generated/graphql";
import removeLocalStorage from "lib/helpers/removeLocalStorage";
import config from "lib/config";
import client from "ApolloClient/index";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const AvatarContainer = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.neutral9};
  display: flex;
  align-items: center;
  /* position: relative;
  top: -4px; */
  justify-content: center;
  .anticon {
    margin-right: 0px;
  }
  @media only screen and (max-width: 600px) {
    margin-right: 24px;
  }
`;

/**
 * https://foundry41.atlassian.net/browse/ACT-26
 * There were issues clicking the avatar on mobile, so, going to try adding padding to make the clickable space bigger?
 */
const AvatarPadding = styled.div`
  height: 50px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AvatarIcon = styled(UserOutlined)`
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral5};
  font-size: 16px;
`;

export default function AvatarDropdown({ menuItems }: { menuItems: any }) {
  const [logout] = useLogoutMutation();
  const history = useHistory();
  const handleOnLogout = async () => {
    try {
      await logout();
      removeLocalStorage(config.authTokenName);
      removeLocalStorage(config.refreshTokenName);
      await client.resetStore();
      // let user know
      message.info("You've successfully logged out...");
      history.push(`/login`);
    } catch (err) {}
  };
  const menu = (
    <Menu style={{ width: 180 }}>
      {menuItems?.map((item: any) => {
        return (
          <Menu.Item key={item.name}>
            <Link to={item.to}>{item.name}</Link>
          </Menu.Item>
        );
      })}
      <Menu.Item key="/admin/new-entry">
        <Link to="/admin/new-entry">New Attachment</Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleOnLogout}>
        Log out
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <AvatarPadding>
        <AvatarContainer>
          <AvatarIcon />
        </AvatarContainer>
      </AvatarPadding>
    </Dropdown>
  );
}
