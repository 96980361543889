import styled from "styled-components";

export default styled.textarea`
  min-height: 50px;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.neutral11};
  color: ${(p) => p.theme.colors.neutral1};
  width: 100%;
  border: 2px solid ${(p) => p.theme.colors.neutral9};
  padding-left: 8px;
  &:focus {
    outline: 0;
    background: #fff;
    color: ${(p) => p.theme.colors.activeFieldText};
    border: 2px solid ${(p) => p.theme.colors.neutral9};
  }
  ::placeholder {
    color: ${(p) => p.theme.colors.neutral7};
  }
`;
