// import apolloClient from "ApolloClient/index";
// import { useState } from "react";
// import ky from "ky";
// import { GetPresignedUploadUrlDocument } from "generated/graphql";

// export default function useUploadFile() {
//     const [uploading, setUploading] = useState<boolean>(false);

//     const uploadFile = async (file: File, path: string) => {
//         try {
//             setUploading(true);
//             console.log(file);
//             const { data } = await apolloClient.mutate({
//                 mutation: GetPresignedUploadUrlDocument,
//                 variables: {
//                     contentType: file.type,
//                     path
//                 },
//             });

//             console.log(file);

//             const formData = new FormData();

//             formData.append("Content-Type", file.type);

//             const fields = JSON.parse(data.getPresignedUploadUrl.fields);

//             console.log(fields);

//             Object.entries(fields).forEach(([k, v]: any) => {
//                 formData.append(k, v);
//             });

//             formData.append("file", file);

//             console.log(formData);

//             const res = await ky.post(data.getPresignedUploadUrl.url, {
//                 body: formData,
//             });

//             const url = `${res.url}/${fields.key}`;

//             console.log(res);
//             console.log(url); // https://example-staging.s3.amazonaws.com/uploads/rNoNtzhJJnz2tCtLf_K1n
//             setUploading(false);

//             const uploadedFile = {
//                 fileType: file.type,
//                 filename: file.name,
//                 url,
//                 baseUrl: res.url,
//                 key: fields.key,
//             };

//             return uploadedFile;
//         } catch (err: any) {
//             setUploading(false);
//             console.log(err);
//             throw new Error(err.message);
//         }
//     };

//     return [
//         uploadFile,
//         uploading,
//     ] as const
// }

import {
  Maybe,
  MultipartUploadCompleteEnum,
  useCompleteMultiUploadMutation,
  useGetPresignedUploadMultiPartMutation,
} from "generated/graphql";
import ky from "ky";

import { useState } from "react";

import { split } from "lodash";
import { toNumber } from "lodash";

const FILE_CHUNK_SIZE = 1024 * 1024 * 5; // 5MB

const getPartsFromFile = (file: File) => {
  const parts: Blob[] = [];
  console.log("size", file.size);
  for (let start = 0; start < file.size; start += FILE_CHUNK_SIZE) {
    const offset = start + FILE_CHUNK_SIZE;
    const chunk = file.slice(start, offset);
    console.log(start, offset);
    parts.push(chunk);
  }
  console.log(parts);
  console.log(
    parts?.map((blob) => blob.size).reduce((a, b) => a + b, 0) === file.size
  );
  return parts;
};

const getProgress = (part: number, length: number) =>
  toNumber(((part / length) * 100).toFixed(0));

export default function useUploadFile() {
  const [uploading, setUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<string | number>(0);
  const [completeMultiUploadMutation] = useCompleteMultiUploadMutation();
  const [getPresignedUploadMultiPartMutation] =
    useGetPresignedUploadMultiPartMutation();

  const uploadFile = async (
    file: File,
    path: string
  ): Promise<
    | {
        fileType: string;
        filename: string;
        key: string;
        url?: string;
        baseUrl?: string | string[];
      }
    | undefined
  > => {
    let presignRes;
    let completedParts: {
      ETag: string;
      PartNumber: number;
    }[] = [];

    try {
      setUploading(true);

      const parts: Blob[] = getPartsFromFile(file);
      const response = await getPresignedUploadMultiPartMutation({
        variables: {
          parts: parts.length,
          contentType: file.type || "application/pdf",
          path,
        },
      });
      presignRes = response?.data?.getPresignedUploadMultiPart;
      const urls: Maybe<string[]> | undefined = presignRes?.urls;

      if (!presignRes?.key) return;

      const uploadedFile: {
        fileType: string;
        filename: string;
        key: string;
        url?: string;
        baseUrl?: string | string[];
      } = {
        fileType: file.type,
        filename: file.name,
        key: presignRes?.key,
      };

      if (!Array.isArray(urls)) {
        console.log("no urls came back for multipart upload");
        return;
      }

      for (let i = 0; i < urls?.length; i++) {
        const url = urls[i];
        const part = parts[i];
        const partNumber = i + 1;

        const res: any = await ky.put(url, {
          body: part,
          timeout: 2147483647,
        });

        const urlParts = split(res?.url, "/");

        uploadedFile.url = `https://${urlParts?.[2]}/${presignRes?.key}`;
        uploadedFile.baseUrl = urlParts?.[2];
        completedParts.push({
          ETag: res?.headers?.get("ETag")?.replace(`"`, "")?.replace(`"`, ""),
          PartNumber: partNumber,
        });

        const prog = getProgress(partNumber, urls.length);
        setProgress(prog);
      }

      if (!presignRes?.uploadId || !presignRes?.key) {
        console.log("key or uploadId did not exist");
        return;
      }

      await completeMultiUploadMutation({
        variables: {
          type: MultipartUploadCompleteEnum.Complete,
          uploadId: presignRes?.uploadId,
          key: presignRes?.key,
          parts: completedParts,
        },
      });
      setUploading(false);
      setProgress(0);
      return uploadedFile;
    } catch (err) {
      console.log(err);
      setUploading(false);
      setProgress(0);
      if (presignRes?.uploadId) {
        completeMultiUploadMutation({
          variables: {
            type: MultipartUploadCompleteEnum.Abort,
            uploadId: presignRes?.uploadId,
            key: presignRes?.key,
            parts: completedParts,
          },
        });
      }
      throw new Error(
        "Something went wrong with your upload. Please make sure it is no larger than 50mb."
      );
    }
  };

  return [uploadFile, uploading, progress] as const;
}
