import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { Maybe, useGetManyUsersQuery, UserTypeEnum } from "generated/graphql";
import DropdownStyleWrapper from "../DropdownStyleWrapper";
import { useState } from "react";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const UserSelectInput = (props: {
  onChange: any;
  value?: Maybe<string>;
  style?: React.CSSProperties;
  placeholder?: string;
  userType?: UserTypeEnum;
}) => {
  const { value, style, userType } = props;
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { data } = useGetManyUsersQuery({
    variables: {
      searchText,
      userType: userType || UserTypeEnum.Customer,
      deactivated: false,
    },
    fetchPolicy: "cache-and-network",
  });
  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        value={value || undefined}
        showArrow={false}
        size="large"
        filterOption={false}
        optionFilterProp="children"
        placeholder={props?.placeholder || "Select a user"}
        showSearch
        onSearch={(search) => setSearchText(search)}
        style={{
          width: "100%",
          ...style,
        }}
      >
        {data?.getManyUsers?.map((user) => {
          if (!user?.id) return null;
          return (
            <Option key={user?.id} value={user?.id}>
              {user?.firstName} {user?.lastName}
            </Option>
          );
        })}
      </Select>
    </DropdownStyleWrapper>
  );
};

// EXPORT
// ================================================================
export default UserSelectInput;
