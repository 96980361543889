import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { EntryPaymentStatusEnum, Maybe } from "generated/graphql";
import paymentStatusToLabel from "lib/helpers/paymentStatusToLabel";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const PaymentStatusSelectInput = (props: {
  onChange: any;
  value?: Maybe<string>;
  style?: React.CSSProperties;
  placeholder?: string;
}) => {
  const { value, style } = props;

  return (
    <Select
      {...props}
      value={value || undefined}
      showArrow={false}
      filterOption={false}
      optionFilterProp="children"
      placeholder={props?.placeholder || "Select a status..."}
      style={{
        width: "100%",
        ...style,
      }}
    >
      {Object.values(EntryPaymentStatusEnum)?.map((key: any) => {
        return (
          <Option key={key} value={key}>
            {paymentStatusToLabel(key as EntryPaymentStatusEnum)}
          </Option>
        );
      })}
    </Select>
  );
};

// EXPORT
// ================================================================
export default PaymentStatusSelectInput;
