import styled from "styled-components";
// LIB
import logo from "lib/media/logo-horizontal-stacked.png";
import AvatarDropdown from "./AvatarDropdown";
import Button from "components/common/Button";
import { Link } from "react-router-dom";

const AppNavContainer = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral8};
  background: #fff;
`;

const NavItemContainer = styled.div<{ active: boolean }>`
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  a {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: ${(p) =>
      p.active ? p.theme.colors.primary2 : p.theme.colors.neutral6} !important;
  }
  a:hover {
    color: ${(p) => p.theme.colors.primary1} !important;
  }
`;

const ActiveBar = styled.div<{ active: boolean }>`
  height: 2px;
  width: 130%;
  position: relative;
  bottom: -9px;
  left: -15%;
  background: ${(p) => (p.active ? p.theme.colors.primary3 : "transparent")};
`;

const NavItem = ({ children, active, to }: any) => (
  <div>
    <NavItemContainer active={active}>
      <Link to={to} style={{ fontSize: 18 }}>
        {children}
      </Link>
    </NavItemContainer>
    <ActiveBar active={active} />
  </div>
);

const MobileCol = styled.div`
  display: inline-flex !important;
  margin-left: 32px;
`;

const LeftContainer = styled.div`
  flex: 2;
  justify-content: flex-start;
  display: flex;
  margin-top: 19px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    display: none !important;
  }
`;

const RightContainer = styled.div`
  flex: 2;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    .avatar-container {
      justify-content: flex-end;
      display: flex;
    }

    a {
      display: none !important;
    }
  }
`;

const Wrapper = styled.div`
  height: 60px;
  margin: auto;
  width: 1200px;
  max-width: 90%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 600px) {
    max-width: 80%;
  }
`;

export default function AppNav({ pathname }: any) {
  const NAV_ITEMS = [
    {
      name: "Home",
      to: "/admin/home",
      active:
        pathname && (pathname.includes("/admin/home") || pathname === "/admin"),
    },
    {
      name: "Attachments",
      to: "/admin/attachments",
      active: pathname && pathname.includes("/admin/attachments"),
    },
    {
      name: "Users",
      to: "/admin/users",
      active: pathname && pathname.includes("/admin/users"),
    },
    {
      name: "Upload",
      to: "/admin/upload",
      active: pathname && pathname.includes("/admin/upload"),
    },
    {
      name: "Admins",
      to: "/admin/super-admins",
      active: pathname && pathname.includes("/admin/super-admins"),
    },
  ];
  return (
    <>
      <AppNavContainer>
        <Wrapper>
          <Link to="/admin/home">
            <img
              src={logo}
              height="36"
              alt="logo"
              style={{ marginBottom: 0, position: "relative", left: -24 }}
            />
          </Link>
          {/* <Tag
            color="cyan"
            style={{ position: "relative", left: -6, bottom: -2 }}
          >
            Admin Portal
          </Tag> */}
          <LeftContainer>
            {NAV_ITEMS?.map((item) => {
              return (
                <MobileCol key={item.name}>
                  <NavItem to={item.to} active={item.active}>
                    {item.name}
                  </NavItem>
                </MobileCol>
              );
            })}
          </LeftContainer>
          <RightContainer>
            <Link
              to="/admin/new-entry"
              style={{
                marginRight: 0,
                display: "inline-block",
                position: "relative",
              }}
            >
              <Button secondary style={{}}>
                + New Attachment
              </Button>
            </Link>
            <div
              className="avatar-container"
              style={{ width: 100, marginLeft: 56, marginTop: 8 }}
            >
              <AvatarDropdown menuItems={NAV_ITEMS} />
            </div>
          </RightContainer>
        </Wrapper>
      </AppNavContainer>
    </>
  );
}
