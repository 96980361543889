import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { EntryPaymentStatusEnum, Maybe } from "generated/graphql";
import paymentStatusToLabel from "lib/helpers/paymentStatusToLabel";
import DropdownStyleWrapper from "../DropdownStyleWrapper";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const PaymentStatusMultiSelectInput = (props: {
  onChange: any;
  value?: Maybe<string>;
  style?: React.CSSProperties;
  placeholder?: string;
}) => {
  const { value, style } = props;

  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        value={value || undefined}
        showArrow={false}
        size="large"
        filterOption={false}
        optionFilterProp="children"
        mode="tags"
        placeholder={props?.placeholder || "Select a payment status"}
        style={{
          width: "100%",
          ...style,
        }}
      >
        {Object.values(EntryPaymentStatusEnum)?.map((key) => {
          return (
            <Option key={key} value={key}>
              {paymentStatusToLabel(key as EntryPaymentStatusEnum)}
            </Option>
          );
        })}
      </Select>
    </DropdownStyleWrapper>
  );
};

// EXPORT
// ================================================================
export default PaymentStatusMultiSelectInput;
