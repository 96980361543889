import styled from "styled-components";

const PageContainer = styled.div`
  width: 1200px;
  margin: auto;
  padding: 32px 0px;
  max-width: 100%;
  @media only screen and (max-width: 600px) {
    max-width: 100%;
    padding: 24px 8px;
  }
`;

export default PageContainer;
