import styled from "styled-components";
import { useForm } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

interface UserFormProps {
  onSubmit: (values: any) => void;
  onCancel: any;
  loading?: boolean;
}

export default function UserForm({
  onSubmit,
  onCancel,
  loading,
}: UserFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;
    onSubmit(data);
  });

  return (
    <Form onSubmit={beforeOnSubmit}>
      <FormItem label="Email" required error={errors?.email?.message}>
        <TextInput
          type="email"
          {...register("email", {
            required: "Email is required.",
          })}
        />
      </FormItem>
      <FormItem label="First Name" required error={errors?.firstName?.message}>
        <TextInput
          type="firstName"
          {...register("firstName", {
            required: "First name is required.",
          })}
        />
      </FormItem>
      <FormItem label="Last Name" required error={errors?.lastName?.message}>
        <TextInput
          type="lastName"
          {...register("lastName", {
            required: "Last name is required.",
          })}
        />
      </FormItem>
      <FormItem>
        <Button
          type="button"
          onClick={onCancel}
          grey
          style={{ width: 100, marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button
          style={{ width: 100 }}
          type="submit"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
